import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AppService } from '../app.service';
import { Auth0Service } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from '../utils.service';
import timezonesData from '../../assets/timezones.json';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public selectedTab: number = 0;
  // public passwordVisible: boolean = false;
  // public passwordVisible2: boolean = false;
  public errMsg: any;
  public characters: any;
  public choosenCharacter: any;
  public selectedImageIndex: any;
  public selectedPlace: any;
  public user: any;
  public localBusinessDetails: any;
  public uploadedFile: File | null = null;
  public timezones = timezonesData;
  public twilioBotDetails: any;
  public language: any;
  public phoneErrorMsg: any;
  public url: any;
  public uploadedData: any;
  public isLoading: boolean = false;
  public keywords: any;
  public isKWPopupOpen: boolean = false;
  public kwPopupIdx: number = 0;
  public kwPopupType?: string; // ["new", "edit"]
  public kwPopupContent = new FormGroup({
    word: new FormControl("", [Validators.minLength(1), Validators.maxLength(100), Validators.required]),
    phonetic: new FormControl("", [Validators.minLength(1), Validators.maxLength(100), Validators.required])
  });

  public businessDetails = {
    id: "",
    business_name: "",
    business_address: "",
    business_phone: "",
    business_type: "",
    poc_contact_number: "",
    agent_phone_number: "",
    time_zone: ""
  }
  public selectedTimezone: any;
  myForm!: FormGroup;

  constructor(private toastr: ToastrService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private appService: AppService, public authService: Auth0Service, private utilsService: UtilsService) {
  }

  async ngOnInit() {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/settings')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    this.localBusinessDetails = this.utilsService.getItem("businessdetails");

    if (this.localBusinessDetails !== null) {
      this.businessDetails = {
        id: this.localBusinessDetails.id,
        business_name: this.localBusinessDetails.business_name,
        business_address: this.localBusinessDetails.business_address,
        business_phone: this.localBusinessDetails.business_phone,
        business_type: this.localBusinessDetails.business_type,
        poc_contact_number: this.localBusinessDetails.poc_contact_number,
        agent_phone_number: this.localBusinessDetails.agent_phone_number,
        time_zone: ""
      };

      if (this.localBusinessDetails.poc_number_array.length) {
        this.myForm = this.fb.group({
          fieldArray: this.fb.array(this.localBusinessDetails.poc_number_array.map((value: any) => this.fb.control(value, Validators.required)))
        });
      } else {
        this.myForm = this.fb.group({
          fieldArray: this.fb.array([
            this.fb.control('', Validators.required),
            this.fb.control('', Validators.required),
            this.fb.control('', Validators.required)
          ])
        });
      }

      if (this.businessDetails.agent_phone_number) {
        await this.getTwilioBotDetails();
      }
      await this.getAgentDetails();

      this.uploadedData = this.localBusinessDetails.menu;
    }
  }

  get fieldArray(): FormArray {
    return this.myForm.get('fieldArray') as FormArray;
  }

  selectedTimeZone(event: any) {
    this.selectedTimezone = this.timezones.find(state => state.abbreviation === event.target.value);
  }

  getTwilioBotDetails() {
    this.appService.getTwilioBotDetails(this.localBusinessDetails.agent_phone_number).subscribe(res => {
      this.twilioBotDetails = res.data.twilio_by_pk;
      this.businessDetails.time_zone = this.twilioBotDetails.time_zone;
      const parts = this.businessDetails.time_zone.split(' ');
      const cityPart = parts[parts.length - 1];
      var stringWithoutBrackets = this.utilsService.removeBrackets(cityPart.trim());
      this.selectedTimezone = this.timezones.find(state => state.abbreviation === stringWithoutBrackets);
    })
  }

  getAgentDetails() {
    this.appService.getAgentDetails().subscribe((res) => {
      this.characters = res.data.agents;
      if (this.localBusinessDetails) {
        let index = this.characters.findIndex((obj: any) => obj.id === this.localBusinessDetails.agent_id);
        this.characterSelected(this.characters[index], index);
      }
    }, (error) => {
      this.toastr.error('Failed to fetch agent details!', 'Error');
      console.error('Error:', error);
    });
  }

  selectLocation(place: any, index: any) {
    this.selectedImageIndex = index;
    this.selectedPlace = place;
  }

  async updateBusinessDetails() {
    try {
      let phoneValid = this.myForm.value.fieldArray.map((item: any) => {
        if (item !== null && item !== "") {
          try {
            return this.utilsService.validateAndFormatPhoneNumber(item);
          } catch (error) {
            console.error("Invalid phone number in array:", error);
            this.toastr.error("Invalid phone number. Please check the length and country code", 'Error', { timeOut: 5000 });
            return null;
          }
        }
        return "";
      });

      if (phoneValid.includes(null)) {
        return;
      }

      await this.updatePOCinBusinessDetails(phoneValid);
    } catch (error) {
      console.error("Error validating and formatting phone number:", error);
      this.phoneErrorMsg = "Please check phone number again";
    }
  }


  async updatePOCinBusinessDetails(phoneValid: any) {
    const body = {
      id: this.businessDetails.id,
      poc_number_array: phoneValid,
      agent_id: this.choosenCharacter.id,
    };

    try {
      const res = await this.appService.updatePOCinBusinessDetails(body).toPromise();
      if (!res.errors) {
        this.utilsService.setItem("businessdetails", res.data.update_businesses_by_pk);
        await this.updateWelcomeMessage();
        this.toastr.success(this.language.updated_success_toastr, 'Success');
        return 'POC updated successfully';
      } else {
        throw new Error(this.language.check_details_error);
      }
    } catch (error) {
      console.error('Error updating POC in business details:', error);
      throw error;
    }
  }

  replaceAgentName(str: string) {

    this.characters.forEach((name: any) => {
      str = str.replace(new RegExp(name.agent_name, 'g'), this.choosenCharacter.agent_name);
    });
    return str;
  }

  async updateWelcomeMessage() {
    try {
      if (this.twilioBotDetails) {
        const welcomeMsg = this.replaceAgentName(this.twilioBotDetails.welcome_message);

        const body = {
          welcome_message: welcomeMsg,
          agent_phone_number: this.businessDetails.agent_phone_number,
          poc_contact_number: this.twilioBotDetails.forward_call_number,
          voice_lang: this.language.language == 'Spanish' ? this.choosenCharacter.spanish_voice_lang : this.choosenCharacter.voice_lang,
          voice_name: this.language.language == 'English' ? this.choosenCharacter.voice_name : this.choosenCharacter.spanish_voice_name,
          time_zone: this.twilioBotDetails.time_zone
        };

        const res = await this.appService.updateAgentDetailsinTwilio(body).toPromise();
        this.twilioBotDetails = res.data.update_twilio_by_pk;
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating welcome message:', error);
    }
  }


  characterSelected(character: any, index: number) {
    this.choosenCharacter = character;
    this.selectedImageIndex = index;
    this.utilsService.playAudio(index);
  }

  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.handleFileInput(event.dataTransfer.files);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.handleFileInput(input.files);
    }
  }

  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  async handleFileInput(files: FileList) {
    this.uploadedFile = files[0];
  }

  async saveCSV() {
    try {
      this.isLoading = true;
      let link = await this.uploadImage();
      await this.uploadMenuToBusiness(link);
      await this.uploadMenu(link);
      await this.updateTwilioBotDetails();
      this.isLoading = false;
    } catch (error) {
      console.error("Error saving CSV:", error);
    }
  }

  async uploadImage() {
    return new Promise((resolve, reject) => {
      this.appService.uploadImage(this.uploadedFile).subscribe(
        (res) => {
          resolve(res.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async uploadMenuToBusiness(url: any) {
    let body = {
      id: this.localBusinessDetails.id,
      menu: url
    };
    return new Promise((resolve, reject) => {
      this.appService.uploadMenuToBusiness(body).subscribe(
        (res) => {
          this.uploadedData = res.data.update_businesses_by_pk.menu;
          this.utilsService.setItem("businessdetails", res.data.update_businesses_by_pk);
          resolve(res.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async uploadMenu(menu_url: any) {
    return new Promise((resolve, reject) => {
      this.appService.uploadMenu(menu_url).subscribe(
        (res) => {
          this.twilioBotDetails.training_data.menu = res.data;
          resolve(res.data);
        }, (error) => {
          reject(error);
        });
    });
  }
  async updateTwilioBotDetails() {
    return new Promise((resolve, reject) => {
      this.appService.updateTwilioBotDetails(this.localBusinessDetails.agent_phone_number, this.twilioBotDetails.training_data).subscribe(
        (res) => {
          console.log(res.data);
          resolve(res.data);
        }, (error) => {
          reject(error);
        });
    });
  }
  removeInput() {
    this.uploadedFile = null;
    this.uploadedData = null;
    this.twilioBotDetails.training_data.menu = {};

    this.uploadMenuToBusiness("");
    this.updateTwilioBotDetails();
  }

  playbackPhonetic(word: any) {
    if(word.length < 1) return;
    
    const data = {
      text: word,
      voice_lang: this.language.language == "Spanish" ? this.choosenCharacter.spanish_voice_lang : this.choosenCharacter.voice_lang,
      voice_name: this.language.language == "Spanish" ? this.choosenCharacter.spanish_voice_name : this.choosenCharacter.voice_name
    };

    return new Promise((resolve, reject) => {
      this.appService.textToSpeech(data).subscribe(
        (res) => {
          const snd = new Audio("data:audio/mpeg;base64," + res.audioContent);
          snd.play();

          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  openKWPopup() {
    this.isKWPopupOpen = !(this.isKWPopupOpen);

    if(this.kwPopupType == "edit") {
     this.kwPopupContent.get("word")?.setValue(this.keywords[this.kwPopupIdx].word);
     this.kwPopupContent.get("phonetic")?.setValue(this.keywords[this.kwPopupIdx].phonetic);
    }
  }

  clickedOutsideKWPopup(event: any) {
    if(event.target.className === "popup-overlay") {
      this.closeKWPopup();
    }
  }

  closeKWPopup() {
    this.isKWPopupOpen = false;
    this.kwPopupContent.reset();
  }

  saveAndCloseKWPopup() {
    this.isKWPopupOpen = false;
    
    const newKW = {
      word: this.kwPopupContent.value.word,
      phonetic: this.kwPopupContent.value.phonetic
    };

    if(this.kwPopupType == "new") {
      this.addKeyword(newKW);
    } else if(this.kwPopupType == "edit") {
      this.updateKeyword(this.kwPopupIdx, { ...newKW, id: this.keywords[this.kwPopupIdx].id });
    }

    this.kwPopupContent.reset();
  }

  getKeywords() {
    return new Promise((resolve, reject) => {
      this.appService.getKeywords().subscribe(
        (res) => {
          this.keywords = res.data.phonetics_pronunciation;
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  addKeyword(kw: any) {
    return new Promise((resolve, reject) => {
      this.appService.addKeyword(kw).subscribe(
        (res) => {
          this.keywords.unshift(res.data.insert_phonetics_pronunciation.returning[0]);
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }  

  updateKeyword(index: number, kw: any) {
    return new Promise((resolve, reject) => {
      this.appService.updateKeyword(kw).subscribe(
        (res) => {
          this.keywords[index] = res.data.update_phonetics_pronunciation.returning[0];
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  deleteKeyword(index: number, kw: any) {
    this.keywords.splice(index, 1);

    return new Promise((resolve, reject) => {
      this.appService.deleteKeyword(kw).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
