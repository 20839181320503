import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from '../app.service';
import { UtilsService } from '../utils.service';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';
const { getPresetsEn, getPresetsEs } = require('../../assets/presets');

const { v4: uuidv4 } = require('uuid');
const myUUID = uuidv4();
@Component({
  selector: 'app-socials',
  templateUrl: './socials.component.html',
  styleUrls: ['./socials.component.scss']
})
export class SocialsComponent implements OnInit {
  public businessDetails: any;
  public googlePlaces: any;
  public isPopupOpen: any;
  public presets: any;
  public selectedImageIndex: any;
  public give_quick_answers: any = [];
  public transfer_call: any = [];
  public direct_caller_to_sites: any = [];
  public take_message: any = [];
  public lead_capture: any = [];
  public selectedPlace: any;
  public dummyTrainingData = {
    "direct_caller_to_sites": [],
    "general_instruction": [],
    "give_quick_answers": [],
    "lead_capture": [],
    "menu": {},
    "restaurant_name": "",
    "take_message": [],
    "transfer_call": [],
    "working_hours": {}
  }

  googleBusinessDetails = new FormGroup({
    businessPhone: new FormControl("")
  });
  websiteBusinessDetails = new FormGroup({
    businessWebsite: new FormControl("")
  });
  public language: any;
  public url: any;


  constructor(private router: Router, private appService: AppService, private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/performance') || this.url.startsWith('/es/dashboard/knowledge') || this.url.startsWith('/es/dashboard/call_logs') || this.url.startsWith('/es/dashboard/flows') || this.url.startsWith('/es/dashboard/new_path') || this.url.startsWith('/es/dashboard/settings')) {
      this.language = configration.es;
      this.presets = getPresetsEn();
    } else {
      this.language = configration.en;
      this.presets = getPresetsEs();;
    }
    this.dummyTrainingData.general_instruction = this.language.general_instruction;

    this.businessDetails = this.utilsService.getItem('businessdetails');
    if (this.businessDetails) {
      this.initializePresets();
      this.initializeSocials();
    }
  }

  private initializePresets(): void {
    if (this.url.startsWith('/es/dashboard/knowledge') || this.url.startsWith('/es/dashboard/flows')) {
      this.presets = getPresetsEs();;
    } else {
      this.presets = getPresetsEn();
    }

    console.log(this.presets, "fff")
  }
  private initializeSocials(): void {
    this.language.socials.forEach((item: any) => {
      switch (item.name) {
        case "Google Places":
          item.added = !!this.businessDetails.business_address;
          break;
        case "Website":
          item.added = !!this.businessDetails.website_url;
          break;
        default:
          break;
      }
    });
  }

  openPopup(details: any): void {
    this.isPopupOpen = details;
  }
  closePopup(): void {
    this.isPopupOpen = "";
    this.googlePlaces = "";
    this.googleBusinessDetails.reset();
    this.websiteBusinessDetails.reset();
  }

  disconnectWebsite(): void {
    const body = {
      id: this.businessDetails.id,
      website_url: ''
    };
    this.updateWebsiteDetails(body);
  }
  connectWebsite(): void {
    const body = {
      id: this.businessDetails.id,
      website_url: this.websiteBusinessDetails.value.businessWebsite
    };
    this.updateWebsiteDetails(body);
  }
  updateWebsiteDetails(body: any): void {
    this.appService.disconnectWebsiteFromBusiness(body).subscribe(
      (res) => this.handleSuccess(res),
      (error) => this.handleError(error));
  }
  private handleSuccess(res: any): void {
    this.utilsService.setItem('businessdetails', res.data.update_businesses_by_pk);
    this.closePopup();
    window.location.reload();
  }
  private handleError(error: any): void {
    console.error('Error updating website details:', error);
  }

  async disconnectGoogle() {
    let body = {
      "id": this.businessDetails.id,
      "business_address": "",
      "business_name": "",
      "business_phone": "",
      "business_type": "",
      "poc_contact_number": "",
      "business_hours": [],
      "map_url": "",
      "poc_number_array": []
    }
    this.appService.disconnectGoogleFromBusiness(body).subscribe(async (res) => {
      this.utilsService.setItem('businessdetails', res.data.update_businesses_by_pk)
      let twiliobody = {
        "id": this.businessDetails.id,
        "forward_call_number": "",
        "restaurant_name": "",
        "welcome_message": "",
        "training_data": this.dummyTrainingData,
        "operating_hours": {},
        "time_zone": "",
        "map_url": ""
      }
      await this.updateTwilioDataWithGoogle(twiliobody);
    });
  }
  async updateTwilioDataWithGoogle(body: any) {
    this.appService.updateTwilioDataWithGoogle(body).subscribe(async () => {
      await this.updateChatbotDetails();
      this.isPopupOpen = "";
    });
  }
  private updateChatbotDetails(): void {
    const body = {
      business_id: this.businessDetails.id,
      data: this.presets
    }
    this.appService.updateChatBotTrainingData(body).subscribe(() => {
      window.location.reload();
    })
  }
  async updateGoogleDetails(body: any) {
    this.appService.disconnectGoogleFromBusiness(body).subscribe(res => {
      this.utilsService.setItem('businessdetails', res.data.update_businesses_by_pk);
      window.location.reload();
    });
  }

  googleLookUp(): void {
    this.appService.getPlaceIdbyPhone(this.googleBusinessDetails.value.businessPhone).subscribe(result => {
      this.googlePlaces = result.candidates;
    })
  }
  selectLocation(place: any, index: any) {
    this.selectedImageIndex = index;
    this.selectedPlace = place;
  }

  finalizeLocation(): void {
    this.appService.getPlacebyeId(this.selectedPlace.place_id).subscribe(async (result) => {
      let validPhone = this.utilsService.validateAndFormatPhoneNumber(result.result.international_phone_number);

      const body = {
        "id": this.businessDetails.id,
        "business_address": result.result.formatted_address,
        "business_name": result.result.name,
        "business_phone": result.result.international_phone_number,
        "business_type": this.selectedPlace.types[0],
        "poc_contact_number": validPhone,
        "business_hours": result.result.opening_hours ? result.result.opening_hours.weekday_text : {},
        "website_url": result.result.website,
        "map_url": result.result.url,
        "poc_number_array": [validPhone, "", ""]
      }

      this.appService.disconnectGoogleFromBusiness(body).subscribe(async (res) => {
        this.utilsService.setItem('businessdetails', res.data.update_businesses_by_pk);
        this.businessDetails = res.data.update_businesses_by_pk;
        await this.initializePresets();
        await this.handlePresets();
        let twiliobody = {
          "id": this.businessDetails.id,
          "forward_call_number": this.businessDetails.poc_contact_number,
          "restaurant_name": this.businessDetails.business_name,
          "welcome_message": `${this.language.thank_you_for_calling} ${this.businessDetails.business_name}. ${this.language.assist_how}`,
          "training_data": this.dummyTrainingData,
          "time_zone": this.businessDetails.business_address ? await this.utilsService.getTimezone(this.utilsService.extractStateFromAddress(this.businessDetails.business_address)).timezone + " " + `(${this.utilsService.getTimezone(this.utilsService.extractStateFromAddress(this.businessDetails.business_address)).abbreviation})` : "",
          "operating_hours": this.businessDetails.business_hours.length ? await this.utilsService.updateTimingArrayTo24hrs(this.businessDetails.business_hours) : {},
          "map_url": this.businessDetails.map_url ? this.businessDetails.map_url : ""
        }
        await this.updateTwilioDataWithGoogle(twiliobody);
      });
    })
  }

  private async handlePresets(): Promise<void> {
    for (const pre of this.presets) {
      let training_set;
      switch (pre.action) {
        case 'Give Quick Answer':
          training_set = this.createQuickAnswerTrainingSet(pre);
          this.give_quick_answers.push(training_set);
          break;
        case 'Transfer Call':
          training_set = this.createTransferCallTrainingSet(pre);
          this.transfer_call.push(training_set);
          break;
        case 'Lead Capture':
          training_set = await this.createLeadCaptureTrainingSet(pre);
          this.lead_capture.push(training_set);
          break;
        case 'Direct Caller to Website':
          training_set = this.createDirectCallerTrainingSet(pre);
          this.direct_caller_to_sites.push(training_set);
          break;
        case 'Take Message':
          training_set = this.createTakeMessageTrainingSet(pre);
          this.take_message.push(training_set);
          break;
      }
    }

    this.updateDummyTrainingData();
  }
  private createQuickAnswerTrainingSet(pre: any): any {
    return {
      question: pre.question,
      answer: pre.answer + this.language.how_else_can_i_help,
      id: pre.id
    };
  }
  private createTransferCallTrainingSet(pre: any): any {
    return {
      user_says: pre.question,
      question: this.language.connect_to_someone_of_help,
      where_agent_will_transfer: pre.phone_number,
      id: pre.id,
      tool_function_name_to_call: "transferCall"
    };
  }
  private async createLeadCaptureTrainingSet(pre: any): Promise<any> {
    const body = {
      questions: pre.form,
      trigger: pre.question,
      form_name: pre.task_name,
      language: this.language.language
    };
    const training_set = await this.appService.leadCaptureForBotTrainingBody(body).toPromise();
    training_set.id = pre.id;
    return training_set;
  }
  private createDirectCallerTrainingSet(pre: any): any {
    return {
      form_type: "direct_caller_to_sites",
      id: pre.id,
      instructions: this.language.follow_instructions,
      steps: [
        {
          id: "1",
          key: "sms_consent",
          question: this.language.over_sms,
          responses: {
            tool_function_name_to_call: "direct_caller_to_sites",
            website_url: pre.website_url
          }
        }
      ],
      trigger: pre.question
    };
  }
  private createTakeMessageTrainingSet(pre: any): any {
    return {
      client_contact_number: pre.phone_number,
      form_type: "take_message",
      id: pre.id,
      instructions: this.language.follow_instructions,
      steps: [
        {
          id: "1",
          question: this.language.whats_the_msg,
          responses: { user_gave_proper_response: { next_step: "2" } }
        },
        {
          id: "2",
          question: this.language.send_over_sms,
          responses: { tool_function_name_to_call: "takeMessage" }
        }
      ],
      trigger: pre.question
    };
  }
  private updateDummyTrainingData(): void {
    this.dummyTrainingData.give_quick_answers = this.give_quick_answers;
    this.dummyTrainingData.take_message = this.take_message;
    this.dummyTrainingData.transfer_call = this.transfer_call;
    this.dummyTrainingData.lead_capture = this.lead_capture;
    this.dummyTrainingData.direct_caller_to_sites = this.direct_caller_to_sites;
    this.dummyTrainingData.restaurant_name = this.businessDetails.business_name;
    this.dummyTrainingData.working_hours = this.businessDetails.business_hours ? {
      answer: JSON.stringify(this.businessDetails.business_hours),
      question: this.language.store_operating_hours
    } : {}
  }

  generateUniqueId() {
    return uuidv4();
  }
}
