import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Service } from '../auth.service';
import { AppService } from '../app.service';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public opened: boolean = false;
  public close: boolean = true;
  public isPopupOpen: boolean = false;
  public userDetails: any;
  public language: any;
  public url: any;

  public links = [
    {
      "name": "Dashboard",
      "link": "/dashboard/knowledge"
    },
    {
      "name": "Performance",
      "link": "/dashboard/performance"
    },
    {
      "name": "Call Logs",
      "link": "/dashboard/call_logs"
    },
    {
      "name": "Path Ways",
      "link": "/dashboard/flows"
    },
    {
      "name": "Settings",
      "link": "/dashboard/settings"
    },
    {
      "name": "Account",
      "link": "/account"
    },
    {
      "name": "Logout",
      "link": ""
    }
  ];

  constructor(private router: Router, public authService: Auth0Service, private appService: AppService) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/performance') || this.url.startsWith('/es/dashboard/knowledge') || this.url.startsWith('/es/dashboard/call_logs') || this.url.startsWith('/es/dashboard/flows') || this.url.startsWith('/es/dashboard/new_path') || this.url.startsWith('/es/dashboard/settings') || this.url.startsWith('/es/account')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    const userDetailsString = localStorage.getItem("userdetails");
    if (userDetailsString !== null) {
      this.userDetails = JSON.parse(userDetailsString);
    }
  }

  open() {
    this.opened = !this.opened;
  }

  change() {
    this.close = !this.close;
  }

  openPopup(): void {
    this.isPopupOpen = true;
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }

  logout(): void {
    this.authService.logout();
    this.closePopup();
  }
}
