<section class="template1">
    <div class="heading">
        <div class="logoDesktop">
            <img src="../../assets/pics/new_logo_final.png" />
        </div>

        <div class="account mobile" (click)="open()">
            <div>
                <h5 *ngIf="userDetails.first_name">{{userDetails.first_name}}</h5>
                <p>{{userDetails.email}}</p>
            </div>
            <span class="material-symbols-outlined person">
                account_circle
            </span>
            <span *ngIf="!opened" class="material-symbols-outlined">
                expand_more
            </span>
            <span *ngIf="opened" class="material-symbols-outlined">
                expand_less
            </span>
        </div>

        <div class="dropdown-content" [ngClass]="{ 'show': opened }">
            <a href="{{language.account_url}}"> <span class="material-symbols-outlined">
                    person
                </span>
                {{language.account}}</a>
            <a (click)="openPopup()"><span class="material-symbols-outlined">
                    logout
                </span>
                {{language.logout}}</a>
        </div>

        <nav class="navbar navbar-inverse navbar-static-top desktop" role="navigation">
            <div class="navbar-header">

                <button *ngIf="close" type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1" (click)="change()">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>

                <button *ngIf="!close" type="button" class="navbar-toggle collapsed" style="padding: 6px 10px;"
                    data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" (click)="change()">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </button>
            </div>

            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav">
                    <li *ngFor="let link of links">
                        <a queryParamsHandling="merge" routerLinkActive="active" class="nav-link"
                            [routerLink]="link.link">{{link.name}}</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</section>

<div class="popup-overlay" *ngIf="isPopupOpen">
    <div class="popup-content">
        <div class="title">
            <button (click)="closePopup()"><span class="material-symbols-outlined">
                    cancel
                </span></button>
        </div>

        <div class="content">
            <p>
                {{language.sure_want_to_logout}}
            </p>
            <button (click)="logout()">
                {{language.logout}}
            </button>
        </div>
        <a (click)="closePopup()">{{language.close}}</a>
    </div>
</div>