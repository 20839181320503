<div class="popup-overlay" *ngIf="isPopupOpen">
    <div class="popup-content">
        <div class="title">
            <h2>{{language.find_number_heading}}</h2>
            <button (click)="closePopup()"><span class="material-symbols-outlined">
                    cancel
                </span></button>
        </div>

        <div class="content">
            <form>
                <div>
                    <label>{{language.select_region}}<span>*</span></label>
                    <select required (change)="lookupForNumber($event)">
                        <option>{{language.select_country}}</option>
                        <option *ngFor="let country of regions" value="{{country.code}}">{{country.name}}
                        </option>
                    </select>
                    <span *ngIf="message">{{message}}</span>
                </div>

                <div class="places" *ngIf="availableNumbers">
                    <label>{{language.choose_number}}</label>
                    <div class="place-div">
                        <a *ngFor="let number of availableNumbers; let i = index" (click)="selectNumber(number, i)"
                            [style.border]="selectedNumberIndex === i ? '2px solid #7B61FF' : 'none'">
                            <span class="material-symbols-outlined">
                                call
                            </span>
                            <div>
                                <h5>{{number.phoneNumber}}</h5>
                                <p>{{number.locality}}, {{number.region}}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <app-spinner *ngIf="!availableNumbers && !selectedNumber || isLoading"
                    [isLoading]="isLoading"></app-spinner>
                <button type="submit" [disabled]="!selectedNumber" (click)="purchasePhoneNumber()">
                    {{language.confirm}}
                </button>
            </form>
        </div>
    </div>
</div>