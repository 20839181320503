<app-navbar></app-navbar>

<main>
    <section class="home-banner">
        <div #leftContainer id="move-image-left">
            <img src="../../assets/pics/floater-phone-ring.png" />
        </div>

        <div id="content">
            <h1>{{language.about.heading}}</h1>
            <h3 *ngFor="let p of language.about.para">{{p}}</h3>
        </div>

        <div #rightContainer id="move-image-right">
            <img src="../../assets/pics/floater-headphones.png" />
        </div>
        <div #bottomleftContainer id="move-image-bottom-left">
            <img src="../../assets/pics/floater-ear.png" />
        </div>
        <div id="move-image-mid-left">
            <img src="../../assets/pics/floater-boom.svg" />
        </div>
        <div id="move-image-mid-mid">
            <img src="../../assets/pics/floater-sound.svg" />
        </div>

    </section>

    <section class="popular">
        <div class="image">
            <img src="../../assets/pics/mission.png">
        </div>
        <div class="content">
            <h4>{{language.our_mission.heading}}</h4>
            <p *ngFor="let p of language.our_mission.para">{{p}}</p>
            <p>
                <b>
                    {{language.our_mission.statement}}
                </b>
            </p>
        </div>
    </section>

    <section class="why">
        <div class="title">
            <h3>{{language.our_values.heading}}</h3>
        </div>

        <div class="features">
            <div *ngFor="let c of language.our_values.content">
                <h4>{{c.title}}</h4>
                <p>{{c.description}}</p>
            </div>
        </div>
    </section>

    <section class="learn-more">
        <div class="content">
            <h4>{{language.demo.heading}}</h4>
            <p>{{language.demo.sub_heading}}</p>
            <a href="{{language.links.request}}">
                <button>{{language.navbar.request_a_demo}}</button>
            </a>
        </div>
        <div class="image">
            <img src="../../assets/pics/4.png" />
        </div>
    </section>
</main>

<app-footer></app-footer>