import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';
@Component({
  selector: 'app-train-agent',
  templateUrl: './train-agent.component.html',
  styleUrls: ['./train-agent.component.scss']
})
export class TrainAgentComponent implements OnInit {
  public businessDetails: any;
  public audio: any;
  dailogueDetails = new FormGroup({
    title: new FormControl(""),
    action: new FormControl(""),
    phase_to_recoganise: new FormControl(""),
    phase_to_respond: new FormControl(""),
    website_url: new FormControl(""),
    phone_number: new FormControl("")
  });
  public language: any;
  public url: any;

  constructor(private router: Router, private appService: AppService, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/knowledge')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    const businessDetailsString = localStorage.getItem("businessdetails");
    if (businessDetailsString !== null) {
      this.businessDetails = JSON.parse(businessDetailsString);
    }
  }

  goback(link: any) {
    this.router.navigate([link])
  }

  addPreview() {
    let body = {
      ...this.dailogueDetails.value,
      audio: this.audio
    }
    this.businessDetails.dummy_voices.push(body)
    this.appService.updateBusinessDetails(this.businessDetails).subscribe(res => {
      localStorage.setItem("businessdetails", JSON.stringify(res.data.update_businesses_by_pk));
      this.toastr.success(this.language.added_success_toastr, 'Success');
      this.router.navigate(['/dashboard/knowledge']);
    });
  }
}
