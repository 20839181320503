<div class="popup-overlay" *ngIf="numberPopup">
    <div class="popup-content">
        <div class="title">
            <h2>{{language.call_here_to_test}}</h2>
            <button (click)="closePopup()"><span class="material-symbols-outlined">
                    cancel
                </span></button>
        </div>

        <div class="content">
            <a href="tel:{{businessDetails.agent_phone_number}}">
                {{businessDetails.agent_phone_number}}
            </a>
        </div>
    </div>
</div>