import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  public success: any;
  userDetails = new FormGroup({
    firstName: new FormControl(""),
    lastName: new FormControl(""),
    email: new FormControl("", [Validators.required, Validators.email]),
    businessPhone: new FormControl("", [Validators.required, Validators.pattern(/^(\+?\d{1,3}|\d{1,4})\s?\(?\d{2,3}\)?[\s.-]?\d{3,4}[\s.-]?\d{3,4}$/)]),
    businessName: new FormControl(""),
    industry: new FormControl(""),
    locationsCount: new FormControl(""),
    characterType: new FormControl("")
  });
  public language: any;
  public url: any;
  public basictrans: any;

  constructor(private appService: AppService, private router: Router, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    let userDetailsString = localStorage.getItem("userDetails");
    this.url = this.router.url;
    if (this.url.startsWith('/es/request')) {
      this.language = configration.website.es;
      this.basictrans = configration.es;
    } else {
      this.language = configration.website.en;
      this.basictrans = configration.en;
    }

    if (userDetailsString !== null) {
      let business = JSON.parse(userDetailsString);
      this.userDetails.patchValue({
        businessName: business.businessName || '',
        businessPhone: business.businessPhone || '',
        characterType: business.characterType || ''
      });
    }
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags(): void {
    this.titleService.setTitle('AI Voice Ordering and Restaurant Solutions | Request a Demo');
    this.metaService.updateTag({ name: 'description', content: 'Transform your restaurant with AI voice assistants and Restaurant solutions at AI table talk. Contact us today to get started and get a free demo to see our technology in action' });
  }

  submit() {
    let body = {
      ...this.userDetails.value,
      "type": "Requested Demo"
    }
    this.appService.formSubmission(body).subscribe(() => {
      this.success = this.basictrans.contact_soon;
      this.clearForm();
    });
  }

  clearForm() {
    this.userDetails.reset({
      firstName: '',
      lastName: '',
      email: '',
      businessPhone: '',
      businessName: '',
      industry: '',
      locationsCount: '',
      characterType: ''
    });
  }
}
