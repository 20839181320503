import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  success: any;
  userDetails = new FormGroup({
    firstName: new FormControl(""),
    lastName: new FormControl(""),
    email: new FormControl("", [Validators.required, Validators.email]),
    phone: new FormControl("", [Validators.required, Validators.pattern(/^(\+?\d{1,3}|\d{1,4})\s?\(?\d{2,3}\)?[\s.-]?\d{3,4}[\s.-]?\d{3,4}$/)]),
    companyName: new FormControl(""),
    siteUrl: new FormControl(""),
    industry: new FormControl(""),
    locationsCount: new FormControl(""),
    intrestedIn: new FormControl(""),
    foundUs: new FormControl("")
  });
  public language: any;
  public url: any;
  public basictrans: any;

  constructor(private appService: AppService, private router: Router) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/contact')) {
      this.language = configration.website.es;
      this.basictrans = configration.es;
    } else {
      this.language = configration.website.en;
      this.basictrans = configration.en;
    }
  }


  submit() {
    let body = {
      ...this.userDetails.value,
      "type": "Contact Page"
    }
    this.appService.formSubmission(body).subscribe(result => {
      this.success = this.basictrans.contact_soon;
      this.clearForm();
    });
  }

  clearForm() {
    this.userDetails.reset({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      siteUrl: '',
      industry: '',
      locationsCount: '',
      intrestedIn: '',
      foundUs: ''
    });
  }
}
