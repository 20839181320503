<app-navbar></app-navbar>

<main>
    <section class="how">

        <div class="services">
            <div class="content">
                <h4>{{language.news_letter.heading}}</h4>
                <p>{{language.news_letter.sub_heading}}</p>
                <div *ngFor="let c of language.news_letter.loop">
                    <img src="../../assets/pics/snowflak-green.svg" />
                    {{c}}
                </div>
            </div>
            <div class="form">
                <h3>{{language.news_letter.form_heading}}</h3>
                <form [formGroup]="userDetails">
                    <div class="flex">
                        <div>
                            <label>{{basictrans.first_name}}</label>
                            <input type="text" formControlName="firstName" />
                        </div>
                        <div>
                            <label>{{basictrans.last_name}}</label>
                            <input type="text" formControlName="lastName" />
                        </div>
                    </div>

                    <div>
                        <label>{{basictrans.email_address}}<span>*</span></label>
                        <input type="email" required formControlName="email" />
                    </div>
                    <button *ngIf="!success" type="submit" [disabled]="!userDetails.valid"
                        (click)="submit()">{{basictrans.sign_up}}</button>
                    <button *ngIf="success" class="submited">{{basictrans.submitted}}</button>
                </form>
            </div>
        </div>
    </section>
</main>

<app-footer></app-footer>