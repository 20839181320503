<main>
    <section>
        <img class="circle_floater" src="../../assets/pics/newfigma/pink_bengium_floater.png" />
        <img class="login_lady" src="../../assets/pics/newfigma/forget_lady.png" />

        <div class="selection">
            <a routerLink="{{language.sign_in_url}}">
                <span class="material-symbols-outlined">
                    arrow_back_ios
                </span>
            </a>
            <div class="characterdiv">
                <img src="../../assets/pics/new_logo_final.png" />
                <div class="title">
                    <h3>{{language.forgot_password}}</h3>
                    <p>{{language.forgot_password_instruction}}</p>
                </div>
                <form [formGroup]="userDetails">
                    <div>
                        <label>{{language.email_address}}<span>*</span></label>
                        <input type="email" formControlName="email" required
                            placeholder="{{language.email_place_holder}}">
                        <span style="color: #52b963;">{{message}}</span>
                    </div>
                    <button type="submit" [disabled]="!userDetails.valid" (click)="reset()">
                        {{language.reset_password}}
                    </button>
                </form>

            </div>
        </div>
        <div class="endliner">
            <p>{{language.copyright}}</p>
        </div>
    </section>
</main>