<main>
    <section>
        <img class="bengium_floater" src="../../assets/pics/newfigma/bengium_floater.png" />
        <img class="circle_floater" src="../../assets/pics/newfigma/circle_floater.png" />
        <img class="triangle_floater" src="../../assets/pics/newfigma/triangle_floater.png" />

        <div class="selection">
            <a routerLink="{{language.links.home}}">
                <span class="material-symbols-outlined">
                    arrow_back_ios
                </span>
            </a>
            <img class="login_lady" src="../../assets/pics/newfigma/login_lady.png" />
            <div class="characterdiv">
                <img src="../../assets/pics/new_logo_final.png" />
                <div class="title">
                    <h3>{{language.sign_in}}</h3>
                    <p>{{language.sign_in_instruction}}</p>
                </div>
                <form [formGroup]="userDetails">
                    <div>
                        <label>{{language.email_address}}<span>*</span></label>
                        <input type="email" formControlName="email" required
                            placeholder="{{language.email_place_holder}}">
                    </div>
                    <div>
                        <label for="">{{language.password}}<span>*</span></label>
                        <div>
                            <input type="{{ passwordVisible ? 'text' : 'password' }}"
                                placeholder="{{language.password_place_holder}}" formControlName="password" required>
                            <i class="material-symbols-outlined" (click)="togglePasswordVisibility()">
                                {{ passwordVisible ? 'visibility_off' : 'visibility' }}
                            </i>
                        </div>
                        <span>{{errMsg}}</span>
                    </div>

                    <p><a routerLink="{{language.forgot_password_url}}">{{language.forgot_password}}</a></p>
                    <button type="submit" [disabled]="!userDetails.valid" (click)="login()">
                        {{language.sign_in}}
                    </button>
                    <app-spinner [isLoading]="isLoading"></app-spinner>
                </form>

                <p>{{language.dont_have_account}} <a routerLink="{{language.sign_up_url}}">{{language.sign_up}}</a></p>
            </div>
        </div>
        <div class="endliner">
            <p>{{language.copyright}}</p>
        </div>
    </section>
</main>