import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  public host: any;
  public lang: any;

  ngOnInit(): void {
    this.host = window.location.href.split('/');
    this.lang = this.host[this.host.length - 3];
    this.host = this.host[this.host.length - 1];
    console.log(this.lang)
  }
}
