<app-header></app-header>

<main>
    <app-sidebar></app-sidebar>
    <content>
        <app-knowledge *ngIf="host == 'knowledge'"></app-knowledge>
        <app-flow *ngIf="host == 'flows'"></app-flow>
        <app-call-logs *ngIf="host == 'call_logs'"></app-call-logs>
        <app-performance *ngIf="host == 'performance'"></app-performance>
        <app-settings *ngIf="host == 'settings'"></app-settings>
        <app-new-pathway *ngIf="host == 'new_path'"></app-new-pathway>
    </content>
</main>