<app-header></app-header>

<main>
    <a class="back" (click)="goback('/dashboard/knowledge')">
        <span class="material-symbols-outlined">
            arrow_back_ios
        </span>
    </a>

    <div class="main">
        <h1>Train Audio</h1>

        <form [formGroup]="dailogueDetails">
            <div class="flex">
                <div>
                    <label>Node Title<span>*</span></label>
                    <input type="text" formControlName="title" required>
                </div>
                <div>
                    <label>Action<span>*</span></label>
                    <select formControlName="action" required>
                        <option value="Give Quick Answer">
                            Give Quick Answer
                        </option>
                        <!-- <option value="Direct Caller to Website">
                            Direct Caller to Website
                        </option>
                        <option value="Take Message">
                            Take Message
                        </option>
                        <option value="Transfer Call">
                            Transfer Call
                        </option> -->
                    </select>
                </div>
                <div style="width: 100%;">
                    <label>Phase to Recoganise<span>*</span></label>
                    <textarea col="10" row="10" type="text" formControlName="phase_to_recoganise" required></textarea>
                </div>
                <div style="width: 100%;">
                    <label>Agent will say<span>*</span></label>
                    <textarea col="10" row="10" type="text" formControlName="phase_to_respond" required></textarea>
                </div>
                <div *ngIf="dailogueDetails.value.action == 'Direct Caller to Website'">
                    <label>Your website link</label>
                    <input type="text" formControlName="website_url">
                </div>
                <div *ngIf="dailogueDetails.value.action == 'Take Message'">
                    <label>Contact to notify</label>
                    <input type="tel" formControlName="phone_number">
                </div>
                <div *ngIf="dailogueDetails.value.action == 'Transfer Call'">
                    <label>Where agent will transfer</label>
                    <input type="tel" formControlName="phone_number">
                </div>
            </div>
            <button type="submit" [disabled]="!dailogueDetails.valid" (click)="addPreview()">
                Save
            </button>
        </form>
    </div>
</main>