import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  success: boolean = false;
  userDetails = new FormGroup({
    firstName: new FormControl(""),
    lastName: new FormControl(""),
    email: new FormControl("", [Validators.email]),
    phone: new FormControl("", [Validators.pattern('^[0-9]+$')]),
    restaurantName: new FormControl("")
  });
  public language: any;
  public url: any;
  public basictrans: any;

  constructor(private appService: AppService, private toastr: ToastrService, private router: Router, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/product')) {
      this.language = configration.website.es;
      this.basictrans = configration.es;
    } else {
      this.language = configration.website.en;
      this.basictrans = configration.en;
    }
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags(): void {
    this.titleService.setTitle('Automated Phone Ordering AI System | AI Table Talk');
    this.metaService.updateTag({ name: 'description', content: 'Transform your business with Virtual Phone Answering Services at AI table talk. Efficient, AI-driven solutions for enhanced customer support and streamlined operations.' });
  }

  private resetFormFields() {
    this.userDetails.reset();
  }

  submit() {
    let body = {
      ...this.userDetails.value,
      "type": "Requested Demo from Product Page"
    }
    this.appService.formSubmission(body).subscribe(result => {
      this.success = true;
      this.toastr.info(this.basictrans.contact_soon, 'INFO');
      this.resetFormFields();
      this.success = false;
    });
  }
}
