<app-navbar></app-navbar>

<main>
    <h1>{{language.privacy.heading}}</h1>
    <div>
        <h2>{{language.privacy.privacy_policy}}</h2>
        <h3>{{language.privacy.introduction}}</h3>
        <p> {{language.privacy.para}}</p>

        <h3>{{language.privacy.we_collect.heading}}</h3>
        <ol>
            <li *ngFor="let p of language.privacy.we_collect.para">
                {{p}}
            </li>
        </ol>

        <h3>{{language.privacy.use_info.heading}}</h3>
        <ul>
            <li *ngFor="let p of language.privacy.use_info.para">
                {{p}}
            </li>
        </ul>

        <h3>{{language.privacy.cookies.heading}}</h3>
        <p>
            {{language.privacy.cookies.para}}
        </p>

        <h3>{{language.privacy.data_sharing.heading}}</h3>
        <p>
            {{language.privacy.data_sharing.para}}
        </p>
        <ul>
            <li *ngFor="let p of language.privacy.data_sharing.content">
                {{p}}
            </li>
        </ul>

        <h3>{{language.privacy.security.heading}}</h3>
        <p>
            {{language.privacy.security.para}}
        </p>

        <h3>{{language.privacy.rights.heading}}</h3>
        <p>
            {{language.privacy.rights.para}}
        </p>

        <h3>{{language.privacy.change_policy.heading}}</h3>
        <p>
            {{language.privacy.change_policy.para}}
        </p>

        <h3>{{language.privacy.contact.heading}}</h3>
        <p>{{language.privacy.contact.para}}
            <a href="mailto:support@aitabletalk.com">support@aitabletalk.com</a>.
        </p>
    </div>

    <!-- <h1>{{language.video_privacy.heading}}</h1>
    <div>
        <h3>{{language.privacy.introduction}}</h3>
        <p>
            {{language.video_privacy.heading}}
        </p>

        <h3>{{language.video_privacy.use_case.heading}}</h3>
        <ol>
            <li *ngFor="let p of language.video_privacy.use_case.para">
                {{p}}
            </li>
        </ol>

        <h3>{{language.video_privacy.integration.heading}}</h3>
        <p>
            {{language.video_privacy.integration.para}}
        </p>
        <ul>
            <li *ngFor="let p of language.video_privacy.integration.content">
                {{p}}
            </li>
        </ul>

        <p *ngFor="let p of language.video_privacy.content">
            {{p}}
        </p>
    </div> -->
</main>

<app-footer></app-footer>