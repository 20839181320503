import { Component, Input, OnInit } from '@angular/core';
import { Auth0Service } from '../auth.service';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-number-popup',
  templateUrl: './number-popup.component.html',
  styleUrls: ['./number-popup.component.scss']
})
export class NumberPopupComponent implements OnInit {
  @Input() numberPopup: boolean = false;

  public language: any;
  public url: any;
  public businessDetails: any;
  public userDetails: any;

  constructor(private router: Router, public authService: Auth0Service, private appService: AppService, private toastr: ToastrService, private fb: FormBuilder) { }

  async ngOnInit() {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/knowledge')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    const userDetailsString = localStorage.getItem("userdetails");
    if (userDetailsString !== null) {
      this.userDetails = JSON.parse(userDetailsString);;
    }

    const businessDetailsString = localStorage.getItem("businessdetails");
    if (businessDetailsString !== null) {
      this.businessDetails = JSON.parse(businessDetailsString);
      this.businessDetails.user_id = this.userDetails.id;
    }
  }
  closePopup(): void {
    this.numberPopup = false;
  }

}
