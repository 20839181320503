<app-navbar></app-navbar>

<main>
    <section class="how">

        <div class="services">
            <div class="content">
                <h4>{{language.product.heading}}</h4>
                <p>{{language.product.sub_heading}}</p>
            </div>
            <div class="form">
                <h3>{{language.product.form_heading}}</h3>
                <form [formGroup]="userDetails">
                    <div>
                        <label>{{basictrans.first_name}}<span>*</span></label>
                        <input type="text" required formControlName="firstName" />
                    </div>
                    <div>
                        <label>{{basictrans.last_name}}<span>*</span></label>
                        <input type="text" required formControlName="lastName" />
                    </div>
                    <div>
                        <label>{{basictrans.email_address}}<span>*</span></label>
                        <input type="email" required formControlName="email" />
                    </div>
                    <div>
                        <label>{{basictrans.business_phone}}<span>*</span></label>
                        <input type="tel" required formControlName="phone" />
                    </div>
                    <div>
                        <label>{{basictrans.restaurant_name}}</label>
                        <input type="text" formControlName="restaurantName" />
                    </div>
                    <button *ngIf="!success" type="submit" [disabled]="!userDetails.valid"
                        (click)="submit()">{{language.navbar.request_a_demo}}</button>
                    <button *ngIf="success" class="submited">{{basictrans.submitted}}</button>
                </form>
            </div>
        </div>
    </section>

    <section class="withus">
        <div class="title">
            <h3>{{language.seamless.heading}}</h3>
        </div>

        <div class="features">
            <div *ngFor="let item of language.seamless.items">
                <img src="{{item.image}}" alt="{{item.alt}}" />
                <h4>{{item.heading}}</h4>
                <p>{{item.description}}</p>
            </div>
        </div>
        <a href="{{language.links.request}}">
            <button>{{language.navbar.request_a_demo}}</button>
        </a>
    </section>

    <section class="why">
        <div class="title">
            <h3>{{language.features.heading}}</h3>
            <p>{{language.features.sub_heading}}
            </p>
        </div>

        <div class="features">
            <div *ngFor="let item of language.features.items">
                <img src="{{item.image}}" />
                <h4>{{item.heading}}</h4>
                <p>{{item.description}}</p>
            </div>
        </div>

        <a href="{{language.links.request}}">
            <button>{{language.navbar.request_a_demo}}</button>
        </a>
    </section>

    <section class="popular">
        <div class="content">
            <h4>{{language.genuine.heading}}</h4>
            <p *ngFor="let p of language.genuine.para">{{p}}</p>
        </div>
        <div class="image">
            <img alt="TableTalk.ai helped Genuine Hospitality" src="../../assets/pics/7.png">
        </div>
    </section>

    <section class="learn-more">
        <div class="image">
            <img alt="Ready To Elevate Your Restaurant With Ai" src="../../assets/pics/table.png" />
        </div>
        <div class="content">
            <h4>{{language.ready_to_elevate.heading}}</h4>
            <a href="{{language.links.request}}">
                <button>{{language.navbar.request_a_demo}}</button>
            </a>
        </div>
    </section>
</main>

<app-footer></app-footer>