<nav>
    <section class="top">
        <div class="flow">
            <a *ngFor="let link of links ; let i = index" (click)="selectLink(link, i)"
                [ngClass]="{'highlighted': selectedLinkIndex === i}">
                <span class="material-symbols-outlined">
                    {{link.icon}}
                </span>
                <p>{{link.name}}</p>
            </a>
        </div>
    </section>

    <section class="bottom">
        <button *ngIf="!businessDetails.agent_phone_number" (click)="open()">
            <img *ngIf="choosenCharacter" src="{{choosenCharacter.icon}}" alt="">
            <div *ngIf="!businessDetails.agent_phone_number">
                <h5> {{language.get_ai_tabletalk}}</h5>
                <p>{{language.demo}}</p>
            </div>
        </button>
        <button *ngIf="businessDetails.agent_phone_number" (click)="openNumberPopup()">
            <img *ngIf="choosenCharacter" src="{{choosenCharacter.icon}}" alt="">
            <div>
                <h5>{{language.your_ai_tabletalk}}</h5>
                <p>{{businessDetails.agent_phone_number}}</p>
            </div>
        </button>
    </section>
</nav>

<app-getnumber [isPopupOpen]="openNumberSelectorPopup" (close)="close()"></app-getnumber>
<app-number-popup [numberPopup]="numberPopup"></app-number-popup>