import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {
  public language: any;
  public url: any;
  constructor(private router: Router, private titleService: Title, private metaService: Meta) {
  }

  ngOnInit() {
    this.url = this.router.url;
    if (this.url.startsWith('/es/integration')) {
      this.language = configration.website.es;
    } else {
      this.language = configration.website.en;
    }
  }
}
