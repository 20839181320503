<app-navbar></app-navbar>

<main>
    <section class="how">

        <div class="services">
            <div class="content">
                <h4>{{language.request.heading}}</h4>
                <p>{{language.request.sub_heading}}</p>
                <ul *ngFor="let c of language.request.contents">
                    <h3>{{c.heading}}</h3>
                    <li *ngFor="let p of c.points">
                        {{p}}
                    </li>
                </ul>
            </div>
            <div class="form">
                <h3>{{language.request.request_form_heading}}</h3>
                <form [formGroup]="userDetails">
                    <div>
                        <label>{{basictrans.first_name}}<span>*</span></label>
                        <input type="text" required formControlName="firstName" />
                    </div>
                    <div>
                        <label>{{basictrans.last_name}}<span>*</span></label>
                        <input type="text" required formControlName="lastName" />
                    </div>

                    <div>
                        <label>{{basictrans.work_email}}<span>*</span></label>
                        <input type="email" required formControlName="email" />
                    </div>
                    <div>
                        <label>{{basictrans.business_name}}<span>*</span></label>
                        <input type="text" required formControlName="businessName" />
                    </div>
                    <div>
                        <label>{{basictrans.business_phone}}<span>*</span></label>
                        <input type="tel" required formControlName="businessPhone" />
                    </div>

                    <div>
                        <label>{{basictrans.company}}<span>*</span></label>
                        <select required formControlName="industry">
                            <option *ngFor="let i of language.request.industries" value={{i.value}}>{{i.title}}</option>
                        </select>
                    </div>

                    <div>
                        <label>{{basictrans.no_of_location}}<span>*</span></label>
                        <select required formControlName="locationsCount">
                            <option value="1">1</option>
                            <option value="2-4">2 - 4</option>
                            <option value="5-9">5 - 9</option>
                            <option value="10-19">10 - 19</option>
                            <option value="20+">20+</option>
                        </select>
                    </div>

                    <div style="display: flex; align-items: center;">
                        <button *ngIf="!success" type="submit" [disabled]="!userDetails.valid"
                            (click)="submit()">{{language.navbar.request_a_demo}}</button>
                        <button *ngIf="success" class="submited">{{basictrans.submitted}}</button>
                        <span>{{success}}</span>
                    </div>

                </form>
            </div>
        </div>
    </section>
</main>

<app-footer></app-footer>