<app-navbar></app-navbar>

<main>
    <section class="how">

        <div class="services">
            <div class="content">
                <h4>{{language.contact.heading}}</h4>
                <p>{{language.contact.sub_heading}}</p>
            </div>
            <div class="form">
                <h3>TableTalk.ai</h3>
                <form [formGroup]="userDetails">
                    <div class="flex">
                        <div>
                            <label>{{basictrans.first_name}}<span>*</span></label>
                            <input type="text" required formControlName="firstName" o />
                        </div>
                        <div>
                            <label>{{basictrans.last_name}}<span>*</span></label>
                            <input type="text" required formControlName="lastName" />
                        </div>
                    </div>

                    <div class="flex">
                        <div>
                            <label>{{basictrans.email}}<span>*</span></label>
                            <input type="email" required formControlName="email" />
                        </div>
                        <div>
                            <label>{{basictrans.business_phone}}<span>*</span></label>
                            <input type="tel" required formControlName="phone" />
                        </div>
                    </div>

                    <div>
                        <label>{{basictrans.restaurant_name}}<span>*</span></label>
                        <input type="text" required formControlName="companyName" />
                    </div>
                    <div>
                        <label>{{basictrans.business_website_url}}</label>
                        <input type="text" formControlName="siteUrl" />
                    </div>

                    <div>
                        <label>{{basictrans.company}}<span>*</span></label>
                        <input type="text" required formControlName="industry" />
                    </div>
                    <div>
                        <label>{{basictrans.no_of_location}}<span>*</span></label>
                        <select required formControlName="locationsCount">
                            <option value="1">1</option>
                            <option value="2-4">2-4</option>
                            <option value="5-9">5-9</option>
                            <option value="10-19">10-19</option>
                            <option value="20+">20+</option>
                        </select>
                    </div>
                    <div>
                        <label>{{basictrans.intrested_because}}<span>*</span></label>
                        <select required formControlName="intrestedIn">
                            <option value="I'm losing business due to missed calls">I'm losing business due to missed
                                calls</option>
                            <option value="I want to reduce my labour cost">I want to reduce my labour cost</option>
                            <option value="I want to improve my customer experience">I want to improve my customer
                                experience</option>
                            <option value="I don't have enough staff to answer the call">I don't have enough staff to
                                answer the call</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div>
                        <label>{{basictrans.how_know_us}}<span>*</span></label>
                        <input type="text" required formControlName="foundUs" />
                    </div>

                    <div style="display: flex; align-items: center;">
                        <button *ngIf="!success" type="submit" [disabled]="!userDetails.valid"
                            (click)="submit()">{{basictrans.submit}}</button>
                        <button *ngIf="success" class="submited">{{basictrans.submitted}}</button>
                        <span>{{success}}</span>
                    </div>
                </form>
            </div>
        </div>
    </section>
</main>

<app-footer></app-footer>