import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  public language: any;
  public url: any;

  constructor(private router: Router, private titleService: Title, private metaService: Meta) { }
  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/pricing')) {
      this.language = configration.website.es;
    } else {
      this.language = configration.website.en;
    }
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags(): void {
    this.titleService.setTitle('Pricing for AI Phone Answering Service | Virtual Assistant Solutions');
    this.metaService.updateTag({ name: 'description', content: 'Discover competitive pricing for our AI phone answering service, which is ideal for restaurants. Automate with our virtual assistant and advanced phone systems.' });
  }

  toggleFaq(index: number) {
    this.language.faq.content[index].open = !this.language.faq.content[index].open;
  }
}
