import { Injectable } from '@angular/core';
import timezonesData from '../assets/timezones.json';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private timezones = timezonesData;

  constructor(private toastr: ToastrService) { }

  transformString(input: string): string {
    return input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  updateArrayValues(array: string[]): string[] {
    return array.map(this.transformString);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  calculateDate(startDate: Date, daysToAdd: number): string {
    const newDate = new Date(startDate);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return this.formatDate(newDate);
  }

  getItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // validateAndFormatPhoneNumber(phoneNumber: string): string {
  //   phoneNumber = phoneNumber.replace(/\D/g, '');
  //   const validPrefixes = ["1", "5", "34"];
  //   const startsWithValidPrefix = validPrefixes.some(prefix => phoneNumber.startsWith(prefix));

  //   if (!startsWithValidPrefix) {
  //     // this.toastr.error("Check Number", 'Error');
  //     throw new Error("Invalid phone number prefix");
  //   }

  //   return phoneNumber;
  // }


  validateAndFormatPhoneNumber(phoneNumber: string): string {
    try {
      const hasPlusSign = phoneNumber.startsWith('+');
      const cleanedNumber = phoneNumber.replace(/\D/g, '');
      const numberToCheck = hasPlusSign ? `+${cleanedNumber}` : cleanedNumber;
      const validPrefixes = ["+1", "+5", "+34"];
      const startsWithValidPrefix = validPrefixes.some(prefix =>
        numberToCheck.startsWith(prefix)
      );

      if (!startsWithValidPrefix) {
        throw new Error("Invalid phone number prefix");
      }

      return numberToCheck;
    } catch (error) {
      console.error("Error validating and formatting phone number:", error);
      this.toastr.error("Invalid phone number. Please check the length and country code", 'Error', { timeOut: 5000 });
      throw error;
    }
  }

  pauseAudio() {
    const audioElements = document.querySelectorAll('audio');
    audioElements.forEach((audio: HTMLAudioElement) => {
      audio.pause();
    });
  }

  playAudio(index: number): void {
    const audioElements = document.querySelectorAll('audio');
    audioElements.forEach((audio: HTMLAudioElement) => {
      if (!audio.paused) {
        audio.pause();
      }
    });

    const audioElement = audioElements[index] as HTMLAudioElement;
    if (audioElement) {
      audioElement.play().catch(error => console.error('Error playing audio:', error));
    }
  }

  getCityFromAddress(address: any) {
    const parts = address.split(',');
    const cityPart = parts[parts.length - 3];
    return cityPart.trim();
  }

  getTimezone(state: string) {
    const stateData = this.timezones.find(item => item.abbreviation.toLowerCase() === state.toLowerCase());
    if (stateData) {
      return { timezone: stateData.timezone, abbreviation: stateData.abbreviation };
    } else {
      return { timezone: 'Timezone not found', abbreviation: 'Abbreviation not found' };
    }
  }

  extractStateFromAddress(address: any) {
    const parts = address.split(',');
    const stateZipPart = parts[parts.length - 2].trim();
    const state = stateZipPart.split(' ')[0];
    return state;
  }

  removeBrackets(str: any) {
    return str.replace(/[\(\)]/g, '');
  }

  transformOpeningHours(inputHours: any) {
    const convertTimeToInt = (timeStr: any) => parseInt(timeStr.replace(':', ''), 10);

    const operatingHours: any = {};

    inputHours.forEach((entry: any) => {
      const [day, hours] = entry.split(': ');
      const periods = hours.split(' , ');
      operatingHours[day.toLowerCase()] = [];

      periods.forEach((period: any) => {
        const [startTime, endTime] = period.split(' – ');
        operatingHours[day.toLowerCase()].push({
          startTime: convertTimeToInt(startTime),
          endTime: convertTimeToInt(endTime)
        });
      });
    });

    return operatingHours;
  }

  async updateTimingArrayTo24hrs(timingArray: any) {
    let openingHours = timingArray.filter((time: any) => !time.includes('Closed'));

    let opening = openingHours.map((day: any) => {
      const [dayOfWeek, timeRanges] = day.split(': ');

      if (!timeRanges) {
        return `${dayOfWeek}: Closed`;
      }

      const ranges = timeRanges.split(', ');
      const convertedRanges = ranges.map((range: any) => {
        const sanitizedRange = range.replace(/–|—|−|—/g, '-').replace(/\s+/g, ' ').trim();
        const [startTime, endTime] = sanitizedRange.split(' - ');

        if (!startTime || !endTime) {
          return `${range} (Invalid Time Range)`;
        }

        let [startHour, startMinute] = startTime.split(':');
        let [endHour, endMinute] = endTime.split(':');

        if (!startHour || !startMinute || !endHour || !endMinute) {
          return `${range} (Invalid Time Format)`;
        }

        startHour = parseInt(startHour, 10);
        endHour = parseInt(endHour, 10);

        if (startHour === 12 && startTime.includes('AM')) {
          startHour = 0; // 12 AM
        } else if (startHour !== 12 && startTime.includes('PM')) {
          startHour += 12; // Add 12 hours for PM
        }

        if (endHour === 12 && endTime.includes('AM')) {
          endHour = 0; // 12 AM
        } else if (endHour !== 12 && endTime.includes('PM')) {
          endHour += 12; // Add 12 hours for PM
        }

        return `${startHour}:${startMinute} – ${endHour}:${endMinute}`;
      });

      return `${dayOfWeek}: ${convertedRanges.join(', ')}`;
    });

    opening = opening.map((time: any) => {
      return time.replace(/(AM|PM)/g, '').trim();
    });

    let response = await this.transformOpeningHours(opening)
    return response;
  }


}
