import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Auth0Service } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: Auth0Service, private router: Router) { }

  canActivate(): boolean {
    if (this.authService.isAuthenticated || this.isAdminLogin()) {
      return true;
    } else {
      this.router.navigate(['/authorize']);
      return false;
    }
  }

  isAdminLogin(): boolean {
    return localStorage.getItem("pass") === 'AdminUser';
  }
}