<main>
    <section class="welcome">
        <div class="intro">
            <img src="../../assets/pics/newfigma/hand.png" alt="">
            <div>
                <h3>{{language.hello}} {{userDetails.first_name ? userDetails.first_name : "User"}}....!</h3>
                <p>{{language.welcome_back}}</p>
            </div>
        </div>
        <hr />
        <div class="greeting">
            <div>
                <img *ngIf="choosenCharacter" src="{{choosenCharacter.icon}}" alt="">
                <p>{{language.welcome_msg}}</p>
            </div>
            <a *ngIf="businessDetails.agent_phone_number" (click)="openNumberPopup()">
                <button><span class="material-symbols-outlined">
                        phone_in_talk
                    </span>{{language.test_call}}</button>
            </a>
            <a *ngIf="!businessDetails.agent_phone_number" (click)="openNumberSelectorPopup()">
                <button><span class="material-symbols-outlined">
                        phone_in_talk
                    </span>{{language.test_call}}</button>
            </a>
        </div>
    </section>

    <app-socials></app-socials>
</main>

<app-getnumber [isPopupOpen]="numberSelectorPopup"></app-getnumber>
<app-number-popup [numberPopup]="numberPopup"></app-number-popup>