import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AppService } from '../app.service';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  public host: any;
  public language: any;
  public url: any;

  constructor(private router: Router, private route: ActivatedRoute, private appService: AppService) {
  }

  ngOnInit(): void {
    // this.url = this.router.url;
    // if (this.url.startsWith('/es/forgot_password')) {
    //   this.language = configration.es;
    // } else {
    //   this.language = configration.en;
    // }

    this.host = window.location.href.split('/');
    this.host = this.host[this.host.length - 1];
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateContent();
    });
  }

  updateContent(): void {
    const currentUrl = this.router.url;
    if (currentUrl.includes('/dashboard/knowledge') || currentUrl.includes('/es/dashboard/knowledge')) {
      this.host = "knowledge";
    } else if (currentUrl.includes('/dashboard/flows') || currentUrl.includes('/es/dashboard/flows')) {
      this.host = "flows";
    } else if (currentUrl.includes('/dashboard/call_logs') || currentUrl.includes('/es/dashboard/call_logs')) {
      this.host = "call_logs";
    } else if (currentUrl.includes('/dashboard/performance') || currentUrl.includes('/es/dashboard/performance')) {
      this.host = "performance";
    } else if (currentUrl.includes('/dashboard/settings') || currentUrl.includes('/es/dashboard/settings')) {
      this.host = "settings";
    } else if (currentUrl.includes('/dashboard/new_path') || currentUrl.includes('/es/dashboard/new_path')) {
      this.host = "new_path";
    }
  }
}
