import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Auth0Service } from '../auth.service';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from '../utils.service';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})

export class AccountSettingsComponent implements OnInit {
  public businessDetails: any;
  public localUserDetails: any;
  public choosenCharacter: any;
  public userDetails: FormGroup;
  public selectedTab: number = 0;
  public message: any;
  emailDetails = new FormGroup({
    email: new FormControl("")
  });
  public language: any;
  public url: any;

  constructor(private router: Router, public authService: Auth0Service, private fb: FormBuilder, private appService: AppService, private toastr: ToastrService, private utilsService: UtilsService) {
    this.userDetails = this.fb.group({
      id: [''],
      first_name: [''],
      last_name: [''],
      email: [{ value: '', disabled: true }],
      phone: [''],
      city: [''],
      state: [''],
      country: [''],
      pincode: ['']
    });
  }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/account')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }
    this.loadBusinessDetails();
    this.loadUserDetails();
  }

  private loadBusinessDetails(): void {
    const businessDetails = this.utilsService.getItem('businessdetails');
    if (businessDetails) {
      this.businessDetails = businessDetails;
      this.fetchAgentDetailsById(businessDetails.agent_id);
    }
  }

  private fetchAgentDetailsById(agentId: string): void {
    this.appService.getAgentDetailsById(agentId).subscribe(res => {
      this.choosenCharacter = res.data.agents_by_pk;
    });
  }

  private loadUserDetails(): void {
    const userDetails = this.utilsService.getItem('userdetails');
    if (userDetails) {
      this.localUserDetails = userDetails;
      this.userDetails.patchValue(userDetails);
      this.emailDetails.patchValue({ email: this.localUserDetails.email });
    }
  }

  reset() {
    let body = {
      email: this.emailDetails.value.email
    };
    this.authService.forgotPassword(body).subscribe(() => {
      this.message = this.language.password_success_msg;
    }, (error) => {
      if (error.status == 200) {
        this.message = this.language.password_success_msg;
      } else {
        this.toastr.error(this.language.check_details_error, 'Error');
      }
    });
  }

  updateUserDetails() {
    this.userDetails.value.email = this.localUserDetails.email;
    this.appService.updateUserDetails(this.userDetails.value).subscribe((res) => {
      if (!res.errors) {
        this.utilsService.setItem("userdetails", res.data.update_users_by_pk);
        this.toastr.success(this.language.updated_success_toastr, 'Success');
      } else {
        this.toastr.error(this.language.check_details_error, 'Error');
      }
    });
  }
}