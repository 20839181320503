<app-navbar></app-navbar>
<main>
    <h1>{{language.blogs.heading}}</h1>
    <div class="content">
        <a [routerLink]="blog.link" *ngFor="let blog of language.blogs.blogs">
            <img src="{{blog.img}}" alt="">
            <h3>{{blog.title}}</h3>
        </a>
    </div>
</main>
<app-footer></app-footer>