import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  success: boolean = false;
  userDetails = new FormGroup({
    firstName: new FormControl(""),
    lastName: new FormControl(""),
    email: new FormControl("", [Validators.email])
  });
  public language: any;
  public url: any;
  public basictrans: any;

  constructor(private appService: AppService, private toastr: ToastrService, private router: Router, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/newsletter')) {
      this.language = configration.website.es;
      this.basictrans = configration.es;
    } else {
      this.language = configration.website.en;
      this.basictrans = configration.en;
    }
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags(): void {
    this.titleService.setTitle('Stay Updated with Our Latest Newsletters | AI Answering Service');
    this.metaService.updateTag({ name: 'description', content: 'Join our AI phone answering service to streamline operations at AI table talk. Perfect for businesses seeking advanced virtual assistants and automated solutions.' });
  }

  private resetFormFields() {
    this.userDetails.reset();
  }

  submit() {
    let body = {
      ...this.userDetails.value,
      "type": "Newsletter Page"
    }
    this.appService.formSubmission(body).subscribe(result => {
      this.success = true;
      this.toastr.success(this.language.thanks_newsletter, 'Success');
      this.resetFormFields();
      this.success = false;
    });
  }
}
