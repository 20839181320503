import { Component, HostListener, ElementRef, ViewChild, Renderer2, OnInit } from '@angular/core';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  @ViewChild('bottomleftContainer', { static: false })
  bottomleftContainer!: ElementRef;
  @ViewChild('bottomrightContainer', { static: false })
  bottomrightContainer!: ElementRef;
  @ViewChild('footerrightContainer', { static: false })
  footerrightContainer!: ElementRef;
  @ViewChild('footerleftContainer', { static: false })
  footerleftContainer!: ElementRef;
  @ViewChild('midrightContainer', { static: false })
  midrightContainer!: ElementRef;

  public language: any;
  public url: any;
  constructor(private renderer: Renderer2, private router: Router, private titleService: Title, private metaService: Meta) {
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    const rectbr = this.bottomrightContainer.nativeElement.getBoundingClientRect();
    const xbr = event.clientX - rectbr.left;
    const ybr = event.clientY - rectbr.top;
    const moveRangebr = 5; // Adjust the movement range as needed
    const moveXbr = ((xbr - rectbr.width / 2) / rectbr.width) * moveRangebr;
    const moveYbr = ((ybr - rectbr.height / 2) / rectbr.height) * moveRangebr;
    this.renderer.setStyle(this.bottomrightContainer.nativeElement, 'transition', 'transform 0.2s ease-out');
    this.renderer.setStyle(this.bottomrightContainer.nativeElement, 'transform', `translate(${moveXbr}px, ${moveYbr}px)`);

    const rectb = this.bottomleftContainer.nativeElement.getBoundingClientRect();
    const xb = event.clientX - rectb.left;
    const yb = event.clientY - rectb.top;
    const moveRangeb = 5; // Adjust the movement range as needed
    const moveXb = ((xb - rectb.width / 2) / rectb.width) * moveRangeb;
    const moveYb = ((yb - rectb.height / 2) / rectb.height) * moveRangeb;
    this.renderer.setStyle(this.bottomleftContainer.nativeElement, 'transition', 'transform 0.2s ease-out');
    this.renderer.setStyle(this.bottomleftContainer.nativeElement, 'transform', `translate(${moveXb}px, ${moveYb}px)`);

    const rectfr = this.footerrightContainer.nativeElement.getBoundingClientRect();
    const xfr = event.clientX - rectfr.left;
    const yfr = event.clientY - rectfr.top;
    const moveRangefr = 5; // Adjust the movement range as needed
    const moveXfr = ((xfr - rectfr.width / 2) / rectfr.width) * moveRangefr;
    const moveYfr = ((yfr - rectfr.height / 2) / rectfr.height) * moveRangefr;
    this.renderer.setStyle(this.footerrightContainer.nativeElement, 'transition', 'transform 0.2s ease-out');
    this.renderer.setStyle(this.footerrightContainer.nativeElement, 'transform', `translate(${moveXfr}px, ${moveYfr}px)`);

    const rectfl = this.footerleftContainer.nativeElement.getBoundingClientRect();
    const xfl = event.clientX - rectfl.left;
    const yfl = event.clientY - rectfl.top;
    const moveRangefl = 5; // Adjust the movement range as needed
    const moveXfl = ((xfl - rectfl.width / 2) / rectfl.width) * moveRangefl;
    const moveYfl = ((yfl - rectfl.height / 2) / rectfl.height) * moveRangefl;
    this.renderer.setStyle(this.footerleftContainer.nativeElement, 'transition', 'transform 0.2s ease-out');
    this.renderer.setStyle(this.footerleftContainer.nativeElement, 'transform', `translate(${moveXfl}px, ${moveYfl}px)`);

    const rectmr = this.midrightContainer.nativeElement.getBoundingClientRect();
    const xmr = event.clientX - rectmr.left;
    const ymr = event.clientY - rectmr.top;
    const moveRangemr = 5; // Adjust the movement range as needed
    const moveXmr = ((xmr - rectmr.width / 2) / rectmr.width) * moveRangemr;
    const moveYmr = ((ymr - rectmr.height / 2) / rectmr.height) * moveRangemr;
    this.renderer.setStyle(this.midrightContainer.nativeElement, 'transition', 'transform 0.2s ease-out');
    this.renderer.setStyle(this.midrightContainer.nativeElement, 'transform', `translate(${moveXmr}px, ${moveYmr}px)`);
  }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/home') || this.url.startsWith('/es')) {
      this.language = configration.website.es;
    } else {
      this.language = configration.website.en;
    }
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags(): void {
    this.titleService.setTitle('Ai Phone Answering Service For Restaurants | Ai Table Talk');
    this.metaService.updateTag({ name: 'description', content: 'Table Talk AI offers 24/7 AI-powered phone answering service for seamless customer engagement and efficient business communication. Enhance your support today.' });
  }
}
