import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth0Service } from '../auth.service';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  userDetails = new FormGroup({
    email: new FormControl("")
  });
  public errMsg: any;
  public message: any;
  public language: any;
  public url: any;

  constructor(private router: Router, public authService: Auth0Service) {
  }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/forgot_password')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }
  }

  goback(link: any) {
    this.router.navigate([link])
  }

  reset() {
    let body = {
      email: this.userDetails.value.email
    }
    this.authService.forgotPassword(body).subscribe(
      () => {
        this.message = this.language.success_reset_password;
      },
      (error) => {
        this.message = this.language.success_reset_password;
      }
    );
  }
}
