<app-navbar></app-navbar>

<main>
    <section class="home-banner">
        <div id="content">
            <h1>{{language.home_banner.heading}}</h1>
            <h3>{{language.home_banner.tagline}}</h3>
            <h4>{{language.home_banner.para}}</h4>
            <p>{{language.home_banner.detail}}</p>
        </div>
    </section>

    <section class="why">
        <div class="title">
            <h3>{{language.why_table_talk.heading}}</h3>
            <p>{{language.why_table_talk.para}}
            </p>
        </div>

        <div class="features">
            <div *ngFor="let f of language.why_table_talk.features">
                <img src="{{f.img}}" alt="{{f.alt}}" />
                <h4>{{f.title}}</h4>
                <p>{{f.description}}</p>
            </div>
        </div>
    </section>

    <section class="withus">
        <div class="title">
            <h3>{{language.with_us.heading}}</h3>
        </div>

        <div class="features">
            <div *ngFor="let f of language.with_us.features">
                <img src="{{f.img}}" alt="{{f.alt}}" />
                <h4>{{f.title}}</h4>
                <p>{{f.description}}</p>
            </div>
        </div>
        <a href="{{language.links.request}}">
            <button>{{language.home_banner.button}}</button>
        </a>
    </section>

    <section class="how">
        <div class="title">
            <h3>{{language.how.heading}}</h3>
        </div>

        <div class="features">
            <div class="services" *ngFor="let s of language.how.services"
                [style]="s.direction == 'reverse' ? 'flex-direction: row-reverse': 'flex-direction: row'">
                <div class="image" [style.border-color]="s.color">
                    <img src="{{s.img}}" alt="{{s.alt}}" />
                </div>
                <div class="content">
                    <h4 [style.color]="s.color">{{s.title}}</h4>
                    <p>{{s.sub_title}}</p>
                    <div *ngFor="let p of s.points">
                        <img src="{{p.img}}" />
                        {{p.text}}
                    </div>
                    <a href="{{language.links.request}}">
                        <button>{{language.home_banner.button}}</button>
                    </a>
                </div>
            </div>
        </div>
    </section>

    <section class="integration">
        <div class="title">
            <h3>{{language.integration.heading}}</h3>
            <p>{{language.integration.para}}
            </p>
        </div>

        <div class="bg-image">
            <img class="girl" alt="Seamless Integration with Leading Platforms With AI"
                src="../../assets/pics/newfigma/Group 172881.png">
            <img class="ear" alt="AI-driven customer support solution"
                src="../../assets/pics/newfigma/floater-ear 1.png" alt="AI-driven customer support solution">
            <img class="gift" alt="AI chatbot solutions for business"
                src="../../assets/pics/newfigma/floater-gift 1.png" alt="AI chatbot solutions for business">
            <img class="sound" src="../../assets/pics/newfigma/floater-sound 2.png">
        </div>

        <div class="end_liner">
            <p>
                {{language.integration.end_liner}}
            </p>
            <a href="{{language.integration.link}}">
                <button>{{language.integration.know_more}}</button>
            </a>
        </div>
    </section>


    <section class="tryit">
        <div #bottomleftContainer id="move-image-left">
            <img alt="AI answering and support platform" src="../../assets/pics/floater-SlangTags.png" />
        </div>

        <div id="content">
            <h2>{{language.try_it.heading}}</h2>
            <p>{{language.try_it.sub_heading}}</p>
            <div>
                <p *ngFor="let i of language.try_it.items">
                    {{i}}
                </p>
            </div>
            <a href="{{language.links.request}}">
                <button>{{language.home_banner.button}}</button>
            </a>
        </div>

        <div #bottomrightContainer id="move-image-right">
            <img alt="Book Your Table With AI" src="../../assets/pics/floater-table.png" />
        </div>
        <div #footerrightContainer id="move-image-bottom-right">
            <img alt="Order Your Food With AI" src="../../assets/pics/floater-delivery-bot.png" />
        </div>
        <div #footerleftContainer id="move-image-bottom-left">
            <img alt="Lock & Unlock With AI" src="../../assets/pics/floater-phone.png" />
        </div>
        <div #midrightContainer id="move-image-mid-right">
            <img alt="AI chatbot solutions for business" src="../../assets/pics/floater-gift.png" />
        </div>
    </section>

    <section class="learn-more">
        <div class="content">
            <h4>{{language.demo.heading}}</h4>
            <p>{{language.demo.sub_heading}}</p>
            <a href="{{language.links.request}}">
                <button>{{language.home_banner.button}}</button>
            </a>
        </div>
        <div class="image">
            <img alt="Ready To Learn More With AI" src="../../assets/pics/4.png" />
        </div>
    </section>
</main>

<app-footer></app-footer>