import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public openNumberSelectorPopup: boolean = false;
  public links: any;
  public selectedLinkIndex: number = 0;
  public choosenCharacter: any;
  public businessDetails: any;
  public language: any;
  public url: any;
  numberPopup: boolean = false;

  constructor(private router: Router, private appService: AppService) { }

  async ngOnInit() {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/performance') || this.url.startsWith('/es/dashboard/knowledge') || this.url.startsWith('/es/dashboard/call_logs') || this.url.startsWith('/es/dashboard/flows') || this.url.startsWith('/es/dashboard/new_path') || this.url.startsWith('/es/dashboard/settings')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    this.links = this.language.links;
    let hostname = window.location.href.split('/');
    let host = hostname[hostname.length - 1];
    const foundIndex = this.links.findIndex((link: any) => link.host === host);
    this.selectedLinkIndex = foundIndex !== -1 ? foundIndex : 0;

    const businessDetailsString = localStorage.getItem("businessdetails");
    if (businessDetailsString !== null) {
      this.businessDetails = JSON.parse(businessDetailsString);

      this.appService.getAgentDetailsById(this.businessDetails.agent_id).subscribe(res => {
        this.choosenCharacter = res.data.agents_by_pk;
      });
    }
  }

  selectLink(link: any, index: number) {
    this.selectedLinkIndex = index;
    this.router.navigate([link.link]);
  }

  open() {
    this.openNumberSelectorPopup = true;
  }

  close() {
    this.openNumberSelectorPopup = false;
  }

  openNumberPopup() {
    this.numberPopup = true;
  }
}
