import { Component, HostListener, ElementRef, ViewChild, Renderer2, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @ViewChild('leftContainer', { static: false })
  leftContainer!: ElementRef;
  @ViewChild('rightContainer', { static: false })
  rightContainer!: ElementRef;
  @ViewChild('bottomleftContainer', { static: false })
  bottomleftContainer!: ElementRef;

  constructor(private renderer: Renderer2, private router: Router, private titleService: Title, private metaService: Meta) {
   }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    const rect = this.leftContainer.nativeElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const moveRange = 5; // Adjust the movement range as needed
    const moveX = ((x - rect.width / 2) / rect.width) * moveRange;
    const moveY = ((y - rect.height / 2) / rect.height) * moveRange;
    this.renderer.setStyle(this.leftContainer.nativeElement, 'transition', 'transform 0.2s ease-out');
    this.renderer.setStyle(this.leftContainer.nativeElement, 'transform', `translate(${moveX}px, ${moveY}px)`);

    const rectr = this.rightContainer.nativeElement.getBoundingClientRect();
    const xr = event.clientX - rectr.left;
    const yr = event.clientY - rectr.top;
    const moveRanger = 5; // Adjust the movement range as needed
    const moveXr = ((xr - rectr.width / 2) / rectr.width) * moveRanger;
    const moveYr = ((yr - rectr.height / 2) / rectr.height) * moveRanger;
    this.renderer.setStyle(this.rightContainer.nativeElement, 'transition', 'transform 0.2s ease-out');
    this.renderer.setStyle(this.rightContainer.nativeElement, 'transform', `translate(${moveXr}px, ${moveYr}px)`);

    const rectb = this.bottomleftContainer.nativeElement.getBoundingClientRect();
    const xb = event.clientX - rectb.left;
    const yb = event.clientY - rectb.top;
    const moveRangeb = 5; // Adjust the movement range as needed
    const moveXb = ((xb - rectb.width / 2) / rectb.width) * moveRangeb;
    const moveYb = ((yb - rectb.height / 2) / rectb.height) * moveRangeb;
    this.renderer.setStyle(this.bottomleftContainer.nativeElement, 'transition', 'transform 0.2s ease-out');
    this.renderer.setStyle(this.bottomleftContainer.nativeElement, 'transform', `translate(${moveXb}px, ${moveYb}px)`);
  }
  public language: any;
  public url: any;

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/about')) {
      this.language = configration.website.es;
    } else {
      this.language = configration.website.en;
    }
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags(): void {
    this.titleService.setTitle('Discover AI Phone Answering Service | Virtual Assistant Solutions');
    this.metaService.updateTag({ name: 'description', content: 'Learn about our restaurant technology solutions featuring an AI-driven virtual assistant and automated phone systems. Optimize customer service and operations effectively.' });
  }
}
