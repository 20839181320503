<main>
    <div class="title">
        <h1>Performance</h1>

        <div *ngIf="!errorMessage">
            <label>{{language.date_from}}</label>
            <input type="date" [(ngModel)]="from_date" name="fromDate" (ngModelChange)="onFromDateChange()" required />
        </div>

        <div *ngIf="!errorMessage">
            <label>{{language.date_to}}</label>
            <input type="date" [(ngModel)]="to_date" name="toDate" [min]="minToDate" [max]="maxToDate" required />
        </div>

        <button *ngIf="!errorMessage" (click)="callLogsWithFilters()">
            <span class="material-symbols-outlined">
                tune
            </span>
            {{language.apply_filter}}</button>
    </div>

    <section class="analytics" *ngIf="records && !errorMessage">
        <div class="stats">
            <span class="material-symbols-outlined">
                phone_in_talk
            </span>
            <div>
                <h3>{{records.totalCalls}}</h3>
                <p>{{language.total_calls}} </p>
            </div>
        </div>
        <div class="stats">
            <span class="material-symbols-outlined">
                phone_callback
            </span>
            <div>
                <h3>{{records.totalInboundCalls}}</h3>
                <p>{{language.inbound_calls}} </p>
            </div>
        </div>
        <div class="stats">
            <span class="material-symbols-outlined">
                phone_forwarded
            </span>
            <div>
                <h3>{{records.totalOutboundCalls}}</h3>
                <p>{{language.outbound_calls}} </p>
            </div>
        </div>
    </section>

    <section class="errorMessage" *ngIf="errorMessage">
        <h3>{{language.upgrade_number}}</h3>
        <button (click)="getNumber()">
            {{language.get_ai_tabletalk}}
        </button>
    </section>
</main>

<main *ngIf="!errorMessage">
    <div class="title">
        <h1>Analytics</h1>
    </div>

    <section class="analytics link-stats" *ngFor="let path of combinedData">
        <h4><a target="_blank" [href]="path.original_url">{{addEllipsisToUrl(path.original_url)}}</a></h4>
        <div class="stats">
            <span class="material-symbols-outlined">
                forward_to_inbox
            </span>
            <div>
                <h3>{{path.total_send_count}}</h3>
                <p>{{language.total_links_sent}} </p>
            </div>
        </div>
        <div class="stats">
            <span class="material-symbols-outlined">
                drafts
            </span>
            <div>
                <h3>{{path.total_clicked_count}}</h3>
                <p>{{language.total_links_clicked}} </p>
            </div>
        </div>
    </section>
    <section class="errorMessage" *ngIf="!combinedData.length">
        <h3>{{language.analytics_err}}</h3>
    </section>
</main>

<app-spinner [isLoading]="isLoading"></app-spinner>
<app-getnumber [isPopupOpen]="numberSelectorPopup"></app-getnumber>