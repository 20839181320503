import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  public url: any;
  public language: any;
  constructor(private router: Router) { }
  
  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/privacy-policy')) {
      this.language = configration.website.es;
    } else {
      this.language = configration.website.en;
    }
  }

}
