import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AppService } from '../app.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from '../utils.service';
import configration from '../../assets/config.json';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-getstarted',
  templateUrl: './getstarted.component.html',
  styleUrls: ['./getstarted.component.scss']
})

export class GetstartedComponent implements OnInit {
  @ViewChild('audioElement') audioElementRef!: ElementRef<HTMLAudioElement>;

  public businessDetailsForStorage: any = {};
  public characters: any;
  public choosenCharacter: any;
  public selectedIndex: any;
  public host: any;
  audioElements: HTMLAudioElement[] = [];
  public isMuted: boolean[] = [];
  public currentPlayingIndex: number = -1;
  public selectedVoice: string = '';
  public selectedPlace: any;
  public textInputs: any = [];
  public apiInputs: any = [];
  public googlePlaces: any = [];
  public isPlayingV1: boolean = false;
  public isPlayingV2: boolean = false;
  public isPlayingV3: boolean = false;
  public currentAudio: HTMLAudioElement | null = null;
  public currentAudioIndex: number | null = null;
  public isLoading: boolean = false;
  public language: any;
  public url: any;

  businessDetails = new FormGroup({
    businessName: new FormControl(""),
    businessAddress: new FormControl(""),
    businessPhone: new FormControl("")
  });

  public history = new Set();
  public phoneErrorMsg: any;

  constructor(private router: Router, private route: ActivatedRoute, private appService: AppService, private toastr: ToastrService, private utilsService: UtilsService, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/get-started/all') || this.url.startsWith('/es/get-started/phone') || this.url.startsWith('/es/get-started/business-details') || this.url.startsWith('/es/get-started/preview') || this.url.startsWith('/es/get-started/listing')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    this.host = window.location.href.split('/');
    this.host = this.host[this.host.length - 1];
    this.updateContent();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateContent();
    });

    this.appService.getAgentDetails().subscribe(res => {
      this.characters = res.data.agents;
      this.characterSelected(this.characters[0], 0);
    });
    this.setTitleAndMetaTags();
  }

  setTitleAndMetaTags(): void {
    this.titleService.setTitle('Virtual assistant answering service | Speak to a Voice AI Expert');
    this.metaService.updateTag({ name: 'description', content: 'Discover seamless virtual assistant solutions tailored to your business needs. Speak to a voice AI expert today for personalized answering services at AI table talk.' });
  }

  async updateContent(): Promise<void> {
    const currentUrl = this.router.url;
    if (this.isCharacterSet() === false) {
      this.router.navigate([this.language.get_started_all]);
    }
    if (currentUrl.includes('/get-started/all')) {
      this.host = "all";
    } else if (currentUrl.includes('/get-started/phone')) {
      this.host = "phone";
    } else if (currentUrl.includes('/get-started/business-details')) {
      this.host = "business-details";
    } else if (currentUrl.includes('/get-started/preview')) {
      this.host = "preview";
      if (Object.keys(this.businessDetailsForStorage).length === 0) {
        if (this.isBusinessDetailsSet() === true) {
          await this.dummyVoicesForPreview();
        } else {
          this.router.navigate([this.language.get_started_phone])
        }
      }
    } else if (currentUrl.includes('/get-started/listing')) {
      this.host = "listing";
      if (this.isBusinessPhoneSet() === true) {
        this.googleLookUp();
      } else {
        this.router.navigate([this.language.get_started_phone])
      }
    }
  }

  isBusinessPhoneSet(): boolean {
    if (this.businessDetails.value.businessPhone) {
      return true;
    }

    if (this.utilsService.getItem('businessPhone')) {
      this.businessDetails.value.businessPhone = this.utilsService.getItem('businessPhone');
      return true;
    }
    return false;
  }

  isCharacterSet(): boolean {
    if (this.choosenCharacter) {
      return true;
    }
    if (this.utilsService.getItem('characterCache')) {
      const choosenCharacterCache = this.utilsService.getItem('characterCache') ? this.utilsService.getItem('characterCache') : {};
      if (Object.keys(choosenCharacterCache).length > 0) {
        this.choosenCharacter = choosenCharacterCache;
        return true;
      }
    }
    return false;
  }

  isBusinessDetailsSet(): boolean {
    if (localStorage.getItem('locationCache')) {
      const localCacheJson = JSON.parse(localStorage.getItem('locationCache') || '{}');
      const type = localCacheJson.type || "";
      if (!type) {
        return false;
      }
      const businessDetails = localCacheJson.businessDetails || {};
      if (Object.keys(businessDetails).length > 0) {
        this.businessDetailsForStorage = businessDetails;
        this.businessDetailsForStorage.agent_id = this.choosenCharacter.id;
        return true;
      }
    }
    return false;
  }

  gonext(link: any) {
    console.log(link)
    this.router.navigate([link]);
  }

  playTextToSpeech(audioUrl: any, index: number) {
    if (this.currentAudioIndex === index) {
      if (this.currentAudio) {
        if (this.currentAudio.paused) {
          this.currentAudio.play();
        } else {
          this.currentAudio.pause();
        }
      }
    } else {
      if (this.currentAudio) {
        this.currentAudio.pause();
      }
      const audio = new Audio(audioUrl.audio);
      audio.play();
      this.currentAudio = audio;
      this.currentAudioIndex = index;
    }

    if (index === 0) {
      this.isPlayingV1 = !this.isPlayingV1;
      this.isPlayingV2 = false;
      this.isPlayingV3 = false;
    } else if (index === 1) {
      this.isPlayingV1 = false;
      this.isPlayingV2 = !this.isPlayingV2;
      this.isPlayingV3 = false;
    } else if (index === 2) {
      this.isPlayingV1 = false;
      this.isPlayingV2 = false;
      this.isPlayingV3 = !this.isPlayingV3;
    }
  }

  addSpaceToPhoneNumber(phoneNumber: any) {
    var phoneNumberArray = phoneNumber.toString().split('');
    var spacedPhoneNumber = phoneNumberArray.join(' ');
    return spacedPhoneNumber;
  }
  async baseToAudio(base64Audio: any) {
    const binaryString = window.atob(base64Audio);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    const audioBlob = new Blob([bytes], { type: 'audio/mpeg' });
    return audioBlob;
  }

  characterSelected(character: any, index: number) {
    this.choosenCharacter = character;
    this.utilsService.setItem('characterCache', this.choosenCharacter)
    this.selectedIndex = index;
    this.utilsService.playAudio(index);
  }
  finalizeAgent() {
    this.businessDetailsForStorage.agent_id = this.choosenCharacter.id;
    this.router.navigate([this.language.get_started_phone]);
  }

  upsertPhoneInLocalStorage(event: any): void {
    if (event.target.value) {
      this.utilsService.setItem('businessPhone', event.target.value)
    }
  }

  googleLookUp() {
    this.isLoading = true;
    this.appService.getPlaceIdbyPhone(this.businessDetails.value.businessPhone).subscribe(result => {
      this.googlePlaces = result.candidates;
      this.router.navigate([this.language.get_started_listing]);
      this.selectedIndex = 0;
      this.selectedPlace = result.candidates[0];
      this.isLoading = false;
    })
  }
  selectLocation(place: any, index: any) {
    this.selectedIndex = index;
    this.selectedPlace = place;
  }
  finalizeLocation() {
    this.appService.getPlacebyeId(this.selectedPlace.place_id).subscribe(async (result) => {
      this.businessDetailsForStorage.business_name = result.result.name;
      this.businessDetailsForStorage.business_address = result.result.formatted_address;
      this.businessDetailsForStorage.business_phone = this.utilsService.validateAndFormatPhoneNumber(result.result.international_phone_number);
      this.businessDetailsForStorage.website_url = result.result.website;
      this.businessDetailsForStorage.business_type = this.selectedPlace.types[0];
      this.businessDetailsForStorage.business_hours = result.result.opening_hours ? result.result.opening_hours.weekday_text : {};
      this.businessDetailsForStorage.map_url = result.result.url;
      this.utilsService.setItem('locationCache', {
        type: "location",
        businessDetails: this.businessDetailsForStorage
      });

      if (this.isLoading === false) {
        this.isLoading = true;
        await this.dummyVoicesForPreview();
        this.isLoading = false;
      }
    });

  }
  async finalizeStaticLocation() {
    this.phoneErrorMsg = null;
    this.businessDetailsForStorage.business_name = this.businessDetails.value.businessName;

    let phoneValid;
    try {
      phoneValid = this.utilsService.validateAndFormatPhoneNumber(
        this.businessDetails.value.businessPhone ? this.businessDetails.value.businessPhone : ""
      );
      this.businessDetailsForStorage.business_phone = phoneValid;

      this.utilsService.setItem('locationCache', {
        type: "staticLocation",
        businessDetails: this.businessDetailsForStorage
      })

      if (this.isLoading === false) {
        this.isLoading = true;
        await this.dummyVoicesForPreview();
        this.isLoading = false;
      }
    } catch (error) {
      console.error("Error validating and formatting phone number:");
      this.phoneErrorMsg = "Please check phone number again";
    }
  }

  async dummyVoicesForPreview() {
    let phone = this.addSpaceToPhoneNumber(this.businessDetailsForStorage.business_phone);

    let texts = [{ "text": `${this.language.hello} ${this.language.thank_you_for_calling} ${this.businessDetailsForStorage.business_name}, ${this.language.this_is}  ${this.choosenCharacter.agent_name} ${this.language.an_ai_agent} ${this.businessDetailsForStorage.business_name}. ${this.language.assist}`, "title": this.language.greeting },
    { "text": `${this.language.to_place_order} ${phone}.`, "title": this.language.order },
    { "text": `${this.language.thank_you_for_calling} ${this.businessDetailsForStorage.business_name}, ${this.language.feel_free_to_reachout} ${phone}. ${this.language.bye_have_goodday}.`, "title": this.language.goodbye }];

    for (const text of texts) {
      let body = {
        "text": text.text,
        "voice_lang": this.language.language == 'Spanish' ? this.choosenCharacter.spanish_voice_lang : this.choosenCharacter.voice_lang,
        "voice_name": this.language.language == 'Spanish' ? this.choosenCharacter.spanish_voice_name : this.choosenCharacter.voice_name
      };

      try {
        const res = await this.appService.textToSpeech(body).toPromise();
        let audio = await this.baseToAudio(res.audioContent);
        let audioFile = await this.appService.convertToAudio(audio);
        if (!this.history.has(text.title)) {
          this.history.add(text.title);
          this.apiInputs.push({ "title": text.title, "phase_to_respond": text.text, "action": "", "phase_to_recoganise": "", "website_url": "", "phone_number": "" });
          this.textInputs.push({ "title": text.title, "audio": audioFile });
        }
        this.gonext(this.language.get_started_preview);
      } catch (error) {
        console.error('Error fetching audio content:', error);
      }
    }
  }

  continueToSignup() {
    this.currentAudio?.pause();

    let body = {
      agent_id: this.businessDetailsForStorage.agent_id,
      business_name: this.businessDetailsForStorage.business_name,
      business_address: this.businessDetailsForStorage.business_address ? this.businessDetailsForStorage.business_address : "",
      business_phone: this.businessDetailsForStorage.business_phone,
      business_type: this.businessDetailsForStorage.business_type ? this.businessDetailsForStorage.business_type : "",
      website_url: this.businessDetailsForStorage.website_url ? this.businessDetailsForStorage.website_url : "",
      poc_contact_number: this.businessDetailsForStorage.business_phone,
      business_hours: this.businessDetailsForStorage.business_hours,
      dummy_voices: this.apiInputs,
      map_url: this.businessDetailsForStorage.map_url,
      poc_number_array: [this.businessDetailsForStorage.business_phone, "", ""]
    }
    this.isLoading = true;
    this.appService.addBusinessDetails(body).subscribe(res => {
      this.utilsService.setItem('businessdetails', res.data.insert_businesses_one)
      this.isLoading = false;
      this.router.navigate([this.language.sign_up_info_url]);
    }, (err) => {
      this.toastr.error('Something went wrong!', 'Error');
    })
  }
}
