import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth0Service } from '../auth.service';
import { AppService } from '../app.service';
import { UtilsService } from '../utils.service';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public errMsg: any;
  public businessDetails: any;
  public passwordVisible: boolean = false;
  public passwordVisible2: boolean = false;
  public isLoading: boolean = false;
  userDetails = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl(""),
    repassword: new FormControl("")
  });
  public language: any;
  public url: any;

  constructor(private router: Router, public authService: Auth0Service, public appService: AppService, private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/signup')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }
    this.businessDetails = this.utilsService.getItem('businessdetails');
    if (!this.businessDetails) {
      if (this.url.startsWith('/es/signup')) {
        this.router.navigate(["/es/get-started/all"]);
      } else {
        this.router.navigate(["/get-started/all"]);
      }
    }
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  togglePasswordVisibility2() {
    this.passwordVisible2 = !this.passwordVisible2;
  }

  signUp() {
    this.isLoading = true;
    this.errMsg = "";

    if (this.userDetails.value.password !== this.userDetails.value.repassword) {
      this.errMsg = "Passwords don't match!";
      this.isLoading = false;
      return;
    }
    if (this.userDetails.value.email !== this.userDetails.value.email?.toLowerCase()) {
      this.errMsg = "Email must be in lowercase.";
      this.isLoading = false;
      return;
    }

    const body = {
      email: this.userDetails.value.email,
      password: this.userDetails.value.password
    };

    this.authService.signUp(body).subscribe(async (res) => {
      try {
        res.business_id = this.businessDetails.id;
        res.language = this.language.language;

        const result = await this.appService.addUserDetails(res).toPromise();

        if (!result.errors) {
          this.utilsService.setItem('userdetails', result.data.insert_users_one);
          if (this.url.startsWith('/es/signup')) {
            window.location.href = '/es/authorize';
          } else {
            window.location.href = '/authorize';

          }
        }
      } catch (error) {
        this.errMsg = "Something went wrong.";
      } finally {
        this.isLoading = false;
      }
    },
      (error) => {
        this.errMsg = "Invalid email or password.";
        this.isLoading = false;
      }
    );
  }
}
