import { Component } from '@angular/core';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent {
  public language: any;
  public url: any;

  constructor(private router: Router, private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/blogs')) {
      this.language = configration.website.es;
    } else {
      this.language = configration.website.en;
    }
  }
}
