import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { UtilsService } from '../utils.service';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent implements OnInit {
  public from_date: any;
  public to_date: any;
  public minToDate: any;
  public maxToDate: any;
  public records: any;
  public errorMessage: any;
  public businessDetails: any;
  public isLoading: boolean = false;
  public numberSelectorPopup: boolean = false;
  public language: any;
  public url: any;
  public paths: any;
  public combinedData: any;
  constructor(private router: Router, private appService: AppService, private utilsService: UtilsService) { }

  async ngOnInit() {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/performance')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    const businessDetails = this.utilsService.getItem('businessdetails');
    if (businessDetails) {
      this.businessDetails = businessDetails;
      this.callLogsWithFilters();
    }

    await this.getLinksPath();
    await this.setTodayDate();
    await this.setOneWeekBeforeDate();
  }

  getLinksPath() {
    const requestBody = { phone_number: this.businessDetails.agent_phone_number };

    this.appService.getLinksPath(requestBody).subscribe(res => {
      this.paths = res.data.short_io_links;
      this.processPaths();
    });
  }

  async processPaths() {
    const promises = this.paths.map((path: any) => this.getLinksDetails(path));
    const results = await Promise.all(promises);
    let array = this.combineDataWithAnalytics(this.paths, results);
    this.combinedData = await this.filterAnalytics(array, this.from_date, this.to_date);

    console.log(array)
    await this.calculateTotalAnalytics()
  }

   filterAnalytics(array: any[], startDate: string, endDate: string) {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
  
    return array.map((item: any) => {
      const filteredAnalytics = item.analytics.filter((analytic: any) => {
        const analyticStart = new Date(analytic.start_timestamp).getTime();
        // Include end date in the range
        return analyticStart >= start && analyticStart <= end;
      });
      console.log('Item:', item);
      console.log('Filtered Analytics:', filteredAnalytics);
      return { ...item, analytics: filteredAnalytics };
    });
  }
  

  async getLinksDetails(path: any) {
    const requestBody = { path: path.path };

    return new Promise((resolve, reject) => {
      this.appService.getLinksDetails(requestBody).subscribe(
        res => {
          resolve({ path: path.path, analytics: res.data.short_io_links_analytics_aggregate.nodes });
        },
        error => reject(error)
      );
    });
  }

  combineDataWithAnalytics(paths: any[], analytics: any[]) {
    const combinedMap = new Map();

    paths.forEach(link => {
      combinedMap.set(link.path, { ...link, analytics: [] });
    });

    analytics.forEach(analytic => {
      const existingLink = combinedMap.get(analytic.path);
      if (existingLink) {
        existingLink.analytics = analytic.analytics;
      }
    });

    return Array.from(combinedMap.values());
  }

  calculateTotalAnalytics() {
    this.combinedData.forEach((link: any) => {
      console.log(link)
      link.total_clicked_count = this.calculateTotalClickedCount(link.analytics);
      link.total_send_count = this.calculateTotalSendCount(link.analytics);
    });
  }

  calculateTotalClickedCount(analytics: any[]) {
    return analytics.reduce((total, analytic) => total + analytic.clicked_count, 0);
  }

  calculateTotalSendCount(analytics: any[]) {
    return analytics.reduce((total, analytic) => total + analytic.send_count, 0);
  }

  onFromDateChange() {
    if (this.from_date) {
      const fromDate = new Date(this.from_date);
      this.minToDate = this.utilsService.formatDate(fromDate);
      this.to_date = this.utilsService.calculateDate(fromDate, 6);
      this.maxToDate = this.utilsService.calculateDate(fromDate, 6);
    } else {
      this.resetDates();
    }
  }
  private resetDates(): void {
    this.minToDate = '';
    this.maxToDate = '';
    this.to_date = '';
  }

  getTodaysTimestamp = () => {
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const localMidnightTimestamp = localMidnight.getTime() - timezoneOffset;
    return localMidnightTimestamp;
  };
  getOneWeekAgoTimestamp = () => {
    const now = new Date();
    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    return oneWeekAgo.getTime();
  };
  async callLogsWithFilters() {
    this.isLoading = true;
    let body = {
      startDate: this.from_date ? this.from_date : new Date(this.getOneWeekAgoTimestamp()),
      endDate: this.to_date ? this.to_date : new Date(this.getTodaysTimestamp()),
      agent_phone: this.businessDetails.agent_phone_number
    }

    this.appService.callAnalyticsWithFilter(body).pipe(
      catchError(error => {
        console.error('Error occurred:', error);
        this.errorMessage = '*Check Agent Number or date.';
        this.isLoading = false;
        return of([]);
      })
    ).subscribe(res => {
      this.records = res;
      this.isLoading = false;
    });

    await this.processPaths()
  }

  setTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(today.getDate()).padStart(2, '0');
    this.to_date = `${year}-${month}-${day}`;
  }
  setOneWeekBeforeDate() {
    const today = new Date();
    today.setDate(today.getDate() - 7);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(today.getDate()).padStart(2, '0');
    this.from_date = `${year}-${month}-${day}`;
  }

  getNumber() {
    this.numberSelectorPopup = true;
  }

  addEllipsisToUrl(url: any) {
    const numChars = 10;
    const comIndex = url.indexOf('.com');

    if (comIndex !== -1) {
      const endIndex = comIndex + 4 + numChars;
      let truncatedUrl = url.slice(0, endIndex);
      if (url.length > endIndex) {
        truncatedUrl += '...';
      }

      return truncatedUrl;
    } else {
      return url;
    }
  }
}
