<app-header></app-header>
<main>
    <a class="back" routerLink="{{language.dashboard_url}}">
        <span class="material-symbols-outlined">
            arrow_back_ios
        </span>
    </a>

    <h1>Settings</h1>

    <section>
        <nav class="nav nav-pills nav-justified">
            <a class="nav-link" [class.active]="selectedTab === 0" (click)="selectedTab = 0">{{language.my_profile}}</a>
            <a class="nav-link" [class.active]="selectedTab === 1"
                (click)="selectedTab = 1">{{language.change_password}}</a>
        </nav>

        <div class="profile" *ngIf="selectedTab === 0">
            <div class="icon" *ngIf="choosenCharacter">
                <img src="{{choosenCharacter.icon}}" alt="">
            </div>
            <form [formGroup]="userDetails">
                <div class="form">
                    <div>
                        <label for="">{{language.first_name}}</label>
                        <input type="text" formControlName="first_name">
                    </div>
                    <div>
                        <label for="">{{language.last_name}}</label>
                        <input type="text" formControlName="last_name">
                    </div>
                    <div>
                        <label for="">{{language.email}}</label>
                        <input type="email" formControlName="email" required>
                    </div>
                    <div>
                        <label for="">{{language.phone}}</label>
                        <input type="tel" formControlName="phone">
                    </div>
                    <div>
                        <label for="">{{language.city}}</label>
                        <input type="text" formControlName="city">
                    </div>
                    <div>
                        <label for="">{{language.state}}</label>
                        <input type="text" formControlName="state">
                    </div>
                    <div>
                        <label for="">{{language.pincode}}</label>
                        <input type="number" formControlName="pincode">
                    </div>
                    <div>
                        <label for="">{{language.country}}</label>
                        <input type="text" formControlName="country">
                    </div>
                </div>
                <button [disabled]="!userDetails.valid" (click)="updateUserDetails()">
                    {{language.save}}
                </button>
            </form>
        </div>

        <div class="password" *ngIf="selectedTab === 1">
            <p>
                {{language.password_msg}}
            </p>
            <form [formGroup]="emailDetails">
                <div class="form">
                    <div>
                        <label>{{language.email_address}}<span>*</span></label>
                        <input type="email" formControlName="email" required>
                        <span style="color: #52b963;">{{message}}</span>
                    </div>
                </div>
                <button type="submit" [disabled]="!emailDetails.valid" (click)="reset()">
                    {{language.reset_password}}
                </button>
            </form>
        </div>
    </section>
</main>