export const environment = {
    production: false,
    auth: {
        domain: 'aitabletalk.us.auth0.com',
        clientId: 'ATtXUotpNzDwrtM3czM7Q9rl6BY8gxvs',
        redirectUri: 'http://localhost:4200', //Not used
        audience: 'https://aitabletalk.us.auth0.com/api/v2/',
        hasuraSecretKey: '3GYs7SKtV7qaJHJ4yqq4GMKf169gOmYDvQOQxLriu850BpSAH86ru5DC9Ecg6Zd2',
        hasuraApiUrl: 'https://table-talk.hasura.app/v1/graphql',
        serviceApiUrl: 'https://table-talk-server.tryantler.com/api',
        apiUrl: 'https://c7zagaquzk.execute-api.ap-south-1.amazonaws.com/production', //'https://q6wq78yljd.execute-api.ap-south-1.amazonaws.com/staging',
        serverUrl: 'https://table-talk-server.tryantler.com'
    }
};