<main>
    <div class="title">
        <h1>Call Logs</h1>
        <div>
            <label>{{language.direction}}</label>
            <select [(ngModel)]="direction" (change)="changeCallLogDirection()">
                <option *ngFor="let text of language.direction_dropdown" value="{{text.value}}">{{text.name}}</option>
            </select>
        </div>
        <div>
            <label>{{language.caller_id}}</label>
            <input type=" text" [(ngModel)]="caller_id" placeholder="{{language.caller_id}}"
                (keydown.enter)="callDetailsById()" />
        </div>

        <button (click)="callDetailsById()">
            <span class="material-symbols-outlined">
                tune
            </span>
            {{language.apply_filter}}</button>
    </div>

    <section *ngIf="records">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">{{language.from}}</th>
                    <th scope="col">{{language.to}}</th>
                    <th scope="col">{{language.date_time}}</th>
                    <th scope="col">{{language.duration}}</th>
                    <th scope="col">{{language.tags}}</th>
                </tr>
            </thead>
            <tbody *ngIf="records.length">
                <tr *ngFor="let rec of records" (click)="openCallDetails(rec)">
                    <td>{{rec.fromFormatted}}</td>
                    <td>{{rec.toFormatted}}</td>
                    <td>{{rec.dateCreated | date : 'medium'}}</td>
                    <td>{{ formatTime(rec.duration) }}</td>
                    <td>{{ formatTags(rec.sid) }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="!records.length && !isLoading">
                <p>{{language.no_records_to_show}}</p>
            </tbody>
        </table>
    </section>
</main>

<div class="popup-overlay" *ngIf="isPopupOpen">
    <div class="popup-content">
        <div class="title">
            <h2>{{language.call_log_details}}</h2>
            <button (click)="closePopup()"><span class="material-symbols-outlined">
                    cancel
                </span></button>
        </div>

        <div class="content">
            <div>
                <h3>SID</h3>
                <p>{{isPopupOpen.sid}}</p>
            </div>
            <div>
                <h3>{{language.from}}</h3>
                <p>{{isPopupOpen.fromFormatted}}</p>
            </div>
            <div>
                <h3>{{language.to}}</h3>
                <p>{{isPopupOpen.toFormatted}}</p>
            </div>
            <div>
                <h3>{{language.date_time}}</h3>
                <p>{{isPopupOpen.dateCreated | date : 'medium'}}</p>
            </div>
            <div>
                <h3>{{language.duration}}</h3>
                <p> {{ formatTime(isPopupOpen.duration) }}</p>
            </div>
            <div>
                <h3>{{language.status}}</h3>
                <p>{{isPopupOpen.status}}</p>
            </div>
            <div>
                <h3>{{language.direction}}</h3>
                <p>{{isPopupOpen.direction}}</p>
            </div>

            <div>
                <h3> {{language.recording}} </h3>
                <p style="color: red; font-size: 12px;" *ngIf="!isPopupOpen.rec">{{language.recording_unavailable}}</p>
                <div>
                    <audio controls>
                        <source [src]="recURL" type="audio/ogg">
                    </audio>
                    <i class="material-symbols-outlined" (click)="downloadFile()">
                        download
                    </i>
                </div>
            </div>
            <div *ngIf="isPopupOpen.intent_keywords_array.length">
                <h3> {{language.keywords}} </h3>
                <span *ngFor="let keyword of isPopupOpen.intent_keywords_array">{{keyword}}</span>
            </div>
        </div>
    </div>
</div>

<app-spinner *ngIf="records" [isLoading]="isLoading"></app-spinner>