import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public close: boolean = true;
  public language: any;
  public url: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/home') || this.url.startsWith('/es') || this.url.startsWith('/es/product') || this.url.startsWith('/es/pricing') || this.url.startsWith('/es/about') || this.url.startsWith('/es/newsletter') || this.url.startsWith('/es/request')) {
      this.language = configration.website.es;
    } else {
      this.language = configration.website.en;
    }
  }

  change() {
    this.close = !this.close;
  }
}
