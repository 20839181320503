import { environment } from '../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { createAuth0Client, Auth0Client, Auth0ClientOptions, User } from '@auth0/auth0-spa-js';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {
  constructor(private http: HttpClient, private auth0: AuthService) { }

  signUp(details: any): Observable<any> {
    const url = `https://${environment.auth.domain}/dbconnections/signup`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      client_id: environment.auth.clientId,
      email: details.email,
      password: details.password,
      connection: 'Username-Password-Authentication'
    };

    return this.http.post(url, body, { headers }).pipe(
      tap((response: any) => {
        // Optionally handle successful signup
      }),
      catchError(err => throwError(err))
    );
  }

  login(details: any): Observable<any> {
    const url = `https://${environment.auth.domain}/oauth/token`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      grant_type: 'password',
      username: details.email,
      password: details.password,
      client_id: environment.auth.clientId,
      audience: environment.auth.audience
    };

    return this.http.post(url, body, { headers }).pipe(
      tap((response: any) => {
        localStorage.setItem('access_token', response.access_token);
      }),
      catchError(err => throwError(err))
    );
  }

  logout() {
    localStorage.clear();
    this.auth0.logout();
  }

  get isAuthenticated(): boolean {
    return !!localStorage.getItem('access_token');
  }

  user$ = this.auth0.user$;

  forgotPassword(details: any): Observable<any> {
    const url = `https://${environment.auth.domain}/dbconnections/change_password`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      email: details.email,
      connection: 'Username-Password-Authentication' // Or your desired connection name
    };

    return this.http.post(url, body, { headers }).pipe(
      catchError(err => throwError(err))
    );
  }

  // resetPassword(newPassword: string, ticket: string): Observable<any> {
  //   const url = `https://${environment.auth.domain}/dbconnections/change_password`;
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   const body = {
  //     client_id: environment.auth.clientId,
  //     ticket,
  //     new_password: newPassword,
  //     connection_id: 'YOUR_CONNECTION_ID' // Replace with your connection ID
  //   };

  //   return this.http.post(url, body, { headers }).pipe(
  //     catchError(err => throwError(err))
  //   );
  // }
}
