<app-navbar></app-navbar>

<main *ngIf="host == 'all'">
    <div class="main">
        <h1>{{language.get_started_instruction}}</h1>
        <div class="progressbar">
            <span class="active">1</span>
            <hr> <span>2</span>
            <hr> <span>3</span>
        </div>
        <section>
            <div class="title">
                <h3>
                    {{language.lets_find_fit_agent}}
                </h3>
                <div>
                    <p>
                        {{language.over_20_k}}
                    </p>
                    <p>
                        {{language.automated_calls}}
                    </p>
                    <p>
                        {{language.saved_hours}}
                    </p>
                </div>
            </div>

            <div class="characterdiv">
                <h4>{{language.choose_agent}}</h4>
                <div class="characters">
                    <div *ngFor="let character of characters; let i = index" (click)="characterSelected(character, i)"
                        [style.border]="selectedIndex === i ? '1px solid #7B61FF' : 'none'">
                        <img *ngIf="selectedIndex === i" class="verify" src="../../assets/pics/newfigma/verified.png" />
                        <img src="{{character.icon}}" />
                        <h5>{{character.agent_name}}</h5>
                        <audio style="display: none;" #audioElement
                            [src]="language.language == 'Spanish' ? character.spanish_voice : character.voice"
                            controls="true" [hidden]="selectedIndex !== i"></audio>
                    </div>
                </div>
                <button *ngIf="choosenCharacter" style="width: 50%;" (click)="finalizeAgent()">
                    {{language.choose}} {{choosenCharacter.agent_name}}
                </button>
            </div>
        </section>
    </div>
</main>

<main *ngIf="host == 'phone'">
    <a class="back" (click)="gonext(language.get_started_all)">
        <span class="material-symbols-outlined">
            arrow_back_ios
        </span>
    </a>

    <div class="main">
        <h1>{{language.get_started_instruction}}</h1>

        <div class="progressbar">
            <span class="active">1</span>
            <hr class="hr-active">
            <span class="active">2</span>
            <hr> <span>3</span>
        </div>

        <section>
            <div class="title">
                <h3>
                    {{language.learn_with_info}}
                </h3>
                <div>
                    <p>
                        {{language.train_in_1_click}}
                    </p>
                    <p>
                        {{language.route_calls}}
                    </p>
                    <p>
                        {{language.link_business_to_tabletalk}}
                    </p>
                </div>
            </div>

            <div class="selection">
                <div class="characterdiv">
                    <form [formGroup]="businessDetails">
                        <h4>{{language.business_details}}</h4>
                        <div>
                            <label>{{language.business_phone}}<span>*</span></label>
                            <input placeholder="{{language.business_phone_placeholder}}" type="tel"
                                formControlName="businessPhone" (change)="upsertPhoneInLocalStorage($event)" required>
                            <span style="color: #808080;">Enter phone number with country code. <br>Acceptable codes:
                                +1, +5, +34.</span>
                        </div>
                        <app-spinner [isLoading]="isLoading"></app-spinner>
                        <button type="submit" [disabled]="!businessDetails.valid" (click)="googleLookUp()">
                            {{language.look_me_up}}
                        </button>
                        <a (click)="gonext(language.get_started_business_details)">{{language.not_on_google}}</a>
                    </form>
                </div>
            </div>
        </section>
    </div>
</main>

<main *ngIf="host == 'business-details'">
    <a class="back" (click)="gonext(language.get_started_all)">
        <span class="material-symbols-outlined">
            arrow_back_ios
        </span>
    </a>

    <div class="main">
        <h1>{{language.get_started_instruction}}</h1>

        <div class="progressbar">
            <span class="active">1</span>
            <hr class="hr-active">
            <span class="active">2</span>
            <hr> <span>3</span>
        </div>

        <section>
            <div class="title">
                <h3>
                    {{language.learn_with_info}}
                </h3>
                <div>
                    <p>
                        {{language.train_in_1_click}}
                    </p>
                    <p>
                        {{language.route_calls}}
                    </p>
                    <p>
                        {{language.link_business_to_tabletalk}}
                    </p>
                </div>
            </div>

            <div class="selection">
                <div class="characterdiv">
                    <form [formGroup]="businessDetails">
                        <h4>{{language.business_details}}</h4>
                        <div>
                            <label>{{language.business_name}}<span>*</span></label>
                            <input type="text" formControlName="businessName" required
                                placeholder="{{language.business_name_placeholder}}">
                        </div>
                        <div>
                            <label>{{language.business_phone}}<span>*</span></label>
                            <input type="tel" formControlName="businessPhone" required
                                placeholder="{{language.business_phone_placeholder}}">
                            <span *ngIf="phoneErrorMsg">{{phoneErrorMsg}}</span>
                            <span style="color: #808080;">Enter phone number with country code. <br>Acceptable codes:
                                +1, +5, +34.</span>
                        </div>
                        <app-spinner [isLoading]="isLoading"></app-spinner>
                        <button type="submit" [disabled]="!businessDetails.valid" (click)="finalizeStaticLocation()">
                            {{language.train_agent}}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    </div>
</main>

<main *ngIf="host == 'listing'">
    <a class="back" (click)="gonext(language.get_started_phone)">
        <span class="material-symbols-outlined">
            arrow_back_ios
        </span>
    </a>

    <div class="main">
        <h1>{{language.get_started_instruction}}</h1>

        <div class="progressbar">
            <span class="active">1</span>
            <hr class="hr-active">
            <span class="active">2</span>
            <hr> <span>3</span>
        </div>

        <section>
            <div class="title">
                <h3>
                    {{language.double_check}}
                </h3>
                <div>
                    <p>
                        {{language.train_in_1_click}}
                    </p>
                    <p>
                        {{language.route_calls}}
                    </p>
                    <p>
                        {{language.link_business_to_tabletalk}}
                    </p>
                </div>
            </div>

            <div class="selection">
                <div class="characterdiv">
                    <div class="places" *ngIf="googlePlaces">
                        <h4 *ngIf="googlePlaces.length === 0 || (googlePlaces.length && googlePlaces.length <= 1)">
                            {{googlePlaces.length}} {{language.listing}}
                        </h4>
                        <h4 *ngIf="googlePlaces.length && googlePlaces.length > 1">{{googlePlaces.length}}
                            {{language.listings}}
                        </h4>

                        <a class="place-div" *ngFor="let place of googlePlaces; let i = index"
                            (click)="selectLocation(place, i)"
                            [style.border]="selectedIndex === i ? '2px solid #7B61FF' : 'none'">
                            <img src="{{place.icon}}" alt="">
                            <div>
                                <h5>{{place.name}}</h5>
                                <p>{{place.formatted_address}}</p>
                            </div>
                        </a>
                        <app-spinner [isLoading]="isLoading"></app-spinner>
                        <button (click)="finalizeLocation()"
                            [disabled]="googlePlaces.length == 0">{{language.train_agent}}</button>
                        <a (click)="gonext(language.get_started_business_details)">{{language.not_on_google}}</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>

<main *ngIf="host == 'preview'">
    <a class="back" (click)="gonext(language.get_started_phone)">
        <span class="material-symbols-outlined">
            arrow_back_ios
        </span>
    </a>

    <div class="main">
        <h1>{{language.get_started_instruction}}</h1>

        <div class="progressbar">
            <span class="active">1</span>
            <hr class="hr-active">
            <span class="active">2</span>
            <hr class="hr-active"> <span>3</span>
        </div>

        <section>
            <div class="title">
                <h3>
                    {{language.look_what_i_learned}}
                </h3>
                <div>
                    <p>
                        {{language.test_agent_on_free}}
                    </p>
                    <p>
                        {{language.boost_transaction}}
                    </p>
                    <p>
                        {{language.ansques}}
                    </p>
                </div>
            </div>

            <div class="selection">
                <div class="characterdiv">
                    <div class="voices">
                        <div *ngFor="let text of textInputs; let i = index" (click)="playTextToSpeech(text, i)">
                            {{text.title}}
                            <audio style="display: none;" #audioElement [src]="text.audio" controls="true"></audio>
                            <button>
                                <ng-container
                                    *ngIf="!(i === 0 && isPlayingV1) && !(i === 1 && isPlayingV2) && !(i === 2 && isPlayingV3)">
                                    <span class="material-symbols-outlined">
                                        volume_up
                                    </span>
                                </ng-container>
                                <ng-container
                                    *ngIf="(i === 0 && isPlayingV1) || (i === 1 && isPlayingV2) || (i === 2 && isPlayingV3)">
                                    <span class="material-symbols-outlined">pause_circle</span>
                                </ng-container>
                            </button>
                        </div>
                        <app-spinner [isLoading]="isLoading"></app-spinner>
                        <button (click)="continueToSignup()">
                            {{language.get_full_demo}}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>

<app-footer></app-footer>


<!-- <button id="playButton" (click)="baseToAudio()">Play Audio</button> -->