const { v4: uuidv4 } = require('uuid');

function generateUniqueId() {
    return uuidv4();
}

function getBusinessDetails() {
    const businessDetailsString = localStorage.getItem('businessdetails');
    if (businessDetailsString !== null) {
        return JSON.parse(businessDetailsString);
    }
    return null;
}

function generatePresetsEn(businessDetail) {
    return [
        {
            "action": "Give Quick Answer",
            "answer": "Okay! I'll hold on.",
            "form": [],
            "id": generateUniqueId(),
            "phone_number": "",
            "question": "Hold On for a sec",
            "task_name": "Hold",
            "website_url": ""
        },
        {
            "action": "Give Quick Answer",
            "answer": `We are located at ${businessDetail.business_address}`,
            "form": [],
            "id": generateUniqueId(),
            "phone_number": "",
            "question": "Where are you located?",
            "task_name": "Location",
            "website_url": ""
        },
        {
            "action": "Take Message",
            "answer": "",
            "form": [],
            "id": generateUniqueId(),
            "phone_number": `${businessDetail.poc_contact_number}`,
            "question": "I would like to leave a message",
            "task_name": "Take a Message",
            "website_url": ""
        },
        {
            "action": "Transfer Call",
            "answer": "",
            "form": [],
            "id": generateUniqueId(),
            "phone_number": `${businessDetail.poc_contact_number}`,
            "question": "I would like to speak to someone",
            "task_name": "Operator",
            "website_url": ""
        },
        {
            "action": "Direct Caller to Website",
            "answer": "",
            "form": [],
            "id": generateUniqueId(),
            "phone_number": "",
            "question": "Where can I find the menu?",
            "task_name": "Menu",
            "website_url": `${businessDetail.website_url}`
        },
        {
            "action": "Lead Capture",
            "answer": "",
            "form": [
                {
                    "question": "May I have your full name?",
                    "type": "Name"
                },
                {
                    "question": "What will be the date and time?",
                    "type": "Date"
                },
                {
                    "question": "How many people is the reservation for?",
                    "type": "Others"
                }
            ],
            "id": generateUniqueId(),
            "phone_number": "",
            "question": "I want to make a reservation",
            "task_name": "Table Reservation",
            "website_url": ""
        }
    ];
}

function generatePresetsEs(businessDetail) {
    return [
        {
            "action": "Give Quick Answer",
            "answer": "¡Entendido! Esperaré.",
            "form": [],
            "id": generateUniqueId(),
            "phone_number": "",
            "question": "Espera un segundo.",
            "task_name": "Espera",
            "website_url": ""
        },
        {
            "action": "Give Quick Answer",
            "answer": `Estamos ubicados en ${businessDetail.business_address}`,
            "form": [],
            "id": generateUniqueId(),
            "phone_number": "",
            "question": "¿Dónde estás ubicado?",
            "task_name": "Ubicación",
            "website_url": ""
        },
        {
            "action": "Take Message",
            "answer": "",
            "form": [],
            "id": generateUniqueId(),
            "phone_number": `${businessDetail.poc_contact_number}`,
            "question": "quisiera dejar un mensaje",
            "task_name": "Déjame un mensaje",
            "website_url": ""
        },
        {
            "action": "Transfer Call",
            "answer": "",
            "form": [],
            "id": generateUniqueId(),
            "phone_number": `${businessDetail.poc_contact_number}`,
            "question": "Me gustaría hablar con alguien.",
            "task_name": "Operador",
            "website_url": ""
        },
        {
            "action": "Direct Caller to Website",
            "answer": "",
            "form": [],
            "id": generateUniqueId(),
            "phone_number": "",
            "question": "¿Dónde puedo encontrar el menú?",
            "task_name": "Menú",
            "website_url": `${businessDetail.website_url}`
        },
        {
            "action": "Lead Capture",
            "answer": "",
            "form": [
                {
                    "question": "¿Puedo tener tu nombre completo?",
                    "type": "Nombre"
                },
                {
                    "question": "¿Cuál será la fecha y la hora?",
                    "type": "Fecha"
                },
                {
                    "question": "¿Para cuántas personas es la reserva?",
                    "type": "Otros"
                }
            ],
            "id": generateUniqueId(),
            "phone_number": "",
            "question": "Quiero hacer una reserva.",
            "task_name": "Reserva de mesa.",
            "website_url": ""
        }
    ];
}

module.exports = {
    getPresetsEn: function() {
        const businessDetail = getBusinessDetails();
        if (businessDetail) {
            return generatePresetsEn(businessDetail);
        }
        return [];
    },
    getPresetsEs: function() {
        const businessDetail = getBusinessDetails();
        if (businessDetail) {
            return generatePresetsEs(businessDetail);
        }
        return [];
    }
};
