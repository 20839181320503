import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import configration from '../../assets/config.json';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  success: boolean = false;
  userDetails = new FormGroup({
    email: new FormControl("")
  });
  public language: any;
  public url: any;
  public basictrans: any;

  constructor(private appService: AppService, private router: Router) { }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/home') || this.url.startsWith('/es') || this.url.startsWith('/es/product') || this.url.startsWith('/es/pricing') || this.url.startsWith('/es/about') || this.url.startsWith('/es/newsletter') || this.url.startsWith('/es/request')) {
      this.language = configration.website.es;
      this.basictrans = configration.es;
    } else {
      this.language = configration.website.en;
      this.basictrans = configration.en;
    }
  }

  submit() {
    let body = {
      ...this.userDetails.value,
      "type": "Footer Newsletter"
    }
    this.appService.formSubmission(body).subscribe(result => {
      this.success = true;
      this.userDetails.reset();
    });
  }

  onInput(event: any) {
    this.success = false;
  }
}
