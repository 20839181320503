import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
const { v4: uuidv4 } = require('uuid');
const myUUID = uuidv4();
import configration from '../../assets/config.json';

@Component({
  selector: 'app-new-pathway',
  templateUrl: './new-pathway.component.html',
  styleUrls: ['./new-pathway.component.scss']
})

export class NewPathwayComponent implements OnInit {
  trainAgent: FormGroup;
  questionForm: FormGroup;
  public businessDetail: any;
  public errorMessage: any;
  public audios: any;
  public twilioBotDetails: any;
  businessDetails = new FormGroup({
    businessName: new FormControl(""),
    businessAddress: new FormControl(""),
    businessPhone: new FormControl("")
  });
  public details: any;
  public language: any;
  public url: any;

  constructor(private router: Router, private appService: AppService, private fb: FormBuilder) {
    this.questionForm = this.fb.group({
      questions: this.fb.array([])
    });
    this.trainAgent = this.fb.group({
      task_name: [''],
      action: [''],
      question: [''],
      answer: [''],
      website_url: [''],
      phone_number: [''],
      form: [[]]
    });
  }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/new_path')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }

    const businessDetailsString = localStorage.getItem("businessdetails");
    if (businessDetailsString !== null) {
      this.businessDetail = JSON.parse(businessDetailsString);
      console.log(this.businessDetail.poc_contact_number, "dudhfudhf")
      // this.businessDetail.poc_number_array = this.businessDetail.poc_number_array.filter((number: any) => number.trim() !== "");
      this.getBotDetails();
      this.getTwilioBotDetails();
    }
  }

  get questions(): FormArray {
    return this.questionForm.get('questions') as FormArray;
  }
  newQuestion(): FormGroup {
    return this.fb.group({
      question: ['', Validators.required],
      type: ['', Validators.required]
    });
  }
  addQuestion() {
    this.questions.push(this.newQuestion());
  }
  removeQuestion(index: number) {
    this.questions.removeAt(index);
  }

  getBotDetails() {
    this.appService.getBotDetails(this.businessDetail.id, false).subscribe(res => {
      this.audios = res.data.chatbot_training_data[0]
    })
  }

  getTwilioBotDetails() {
    this.appService.getTwilioBotDetails(this.businessDetail.agent_phone_number).subscribe(res => {
      this.twilioBotDetails = res.data.twilio_by_pk;
    })
  }

  async finalSaveAddMore() {
    this.errorMessage = "";
    this.trainAgent.value.form = this.questionForm.value.questions;
    const newId = this.generateUniqueId();
    this.trainAgent.value.id = newId;
    this.audios.data.push(this.trainAgent.value);

    let training_set;
    switch (this.trainAgent.value.action) {
      case 'Give Quick Answer':
        training_set = {
          question: this.trainAgent.value.question,
          answer: this.trainAgent.value.answer + this.language.how_else_can_i_help,
          id: newId
        }
        this.twilioBotDetails.training_data.give_quick_answers.push(training_set)
        break;
      case 'Transfer Call':
        training_set = {
          user_says: this.trainAgent.value.question,
          question: this.language.connect_to_someone_of_help,
          where_agent_will_transfer: this.trainAgent.value.phone_number,
          id: newId,
          tool_function_name_to_call: "transferCall",
        }
        this.twilioBotDetails.training_data.transfer_call.push(training_set)
        break;
      case 'Lead Capture':
        let requestBody = {
          questions: this.trainAgent.value.form,
          trigger: this.trainAgent.value.question,
          form_name: this.trainAgent.value.task_name,
          language: this.language.language
        }
        training_set = await this.appService.leadCaptureForBotTrainingBody(requestBody).toPromise();
        training_set.id = newId;
        this.twilioBotDetails.training_data.lead_capture.push(training_set)
        break;
      case 'Direct Caller to Website':
        training_set = {
          "form_type": "direct_caller_to_sites",
          "id": newId,
          "instructions": this.language.follow_instructions,
          "steps": [
            {
              "id": "1",
              "key": "sms_consent",
              "question": this.language.over_sms,
              "responses": {
                "tool_function_name_to_call": "direct_caller_to_sites",
                "website_url": this.trainAgent.value.website_url
              }
            }
          ],
          "trigger": this.trainAgent.value.question
        };
        this.twilioBotDetails.training_data.direct_caller_to_sites.push(training_set)
        break;
      case 'Take Message':
        training_set = {
          "client_contact_number": this.trainAgent.value.phone_number,
          "form_type": "take_message",
          "id": newId,
          "instructions": this.language.follow_instructions,
          "steps": [
            {
              "id": "1",
              "key": "sms_consent",
              "question": this.language.whats_the_msg,
              "responses": { "user_gave_proper_response": { "next_step": "2" } }
            },
            {
              "id": "2",
              "question": this.language.send_over_sms,
              "responses": { "tool_function_name_to_call": "takeMessage" }
            }
          ],
          "trigger": this.trainAgent.value.question
        };
        this.twilioBotDetails.training_data.take_message.push(training_set)
        break;
    }
    let botDetails = await this.appService.updateTwilioBotDetails(this.twilioBotDetails.phone_number, this.twilioBotDetails.training_data).toPromise();
    let body = {
      business_id: this.businessDetail.id,
      data: this.audios.data
    }
    this.appService.updateChatBotTrainingData(body).subscribe(res => {
      if (this.url.startsWith('/es/dashboard/new_path')) {
        window.location.href = '/es/dashboard/flows';
      } else {
        window.location.href = '/dashboard/flows';
      }
    })
  }

  onOptionChange(event: any): void {
    switch (event.target.value) {
      case 'Give Quick Answer':
        this.trainAgent.setValue({
          task_name: "",
          action: event.target.value,
          question: "",
          answer: "",
          website_url: "",
          phone_number: this.businessDetail.poc_contact_number,
          form: []
        });
        break;
      case 'Direct Caller to Website':
        this.trainAgent.setValue({
          task_name: "",
          action: event.target.value,
          question: this.language.place_order_online,
          answer: "",
          website_url: this.businessDetail.website_url,
          phone_number: this.businessDetail.poc_contact_number,
          form: []
        });
        break;
      case 'Take Message':
        this.trainAgent.setValue({
          task_name: "",
          action: event.target.value,
          question: this.language.place_order,
          answer: "",
          website_url: "",
          phone_number: this.businessDetail.poc_contact_number,
          form: []
        });
        break;
      case 'Transfer Call':
        this.trainAgent.setValue({
          task_name: "",
          action: event.target.value,
          question: this.language.pay_online,
          answer: "",
          website_url: "",
          phone_number: this.businessDetail.poc_contact_number,
          form: []
        });
        break;
      case 'Lead Capture':
        this.addQuestion();
        this.trainAgent.setValue({
          task_name: "",
          action: event.target.value,
          question: this.language.lead_capture,
          answer: "",
          website_url: "",
          phone_number: this.businessDetail.poc_contact_number,
          form: []
        });
        break;
    }
  }

  generateUniqueId() {
    return uuidv4();
  }
}