import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { UtilsService } from '../utils.service';
import { saveAs } from 'file-saver';
import configration from '../../assets/config.json';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-call-logs',
  templateUrl: './call-logs.component.html',
  styleUrls: ['./call-logs.component.scss']
})

export class CallLogsComponent implements OnInit {
  public from_date: any;
  public to_date: any;
  public minToDate: any;
  public maxToDate: any;
  public direction: any = "Incoming";
  public records: any = [];
  public recordsSidTagsMap: any = {};
  public isLoading: boolean = false;
  public caller_id: any;
  public isPopupOpen: any;
  public businessDetails: any;
  numberSelectorPopup: boolean = false;
  public language: any;
  public url: any;
  public apiUrl: any;
  public recURL: any;

  constructor(private router: Router, private appService: AppService, private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.apiUrl = environment.auth.serverUrl;
    this.url = this.router.url;
    if (this.url.startsWith('/es/dashboard/call_logs')) {
      this.language = configration.es;
    } else {
      this.language = configration.en;
    }
    const businessDetails = this.utilsService.getItem('businessdetails');
    if (businessDetails) {
      this.businessDetails = businessDetails;
      this.fetchCallLogs();
    }
  }

  private fetchCallLogs(): void {
    this.isLoading = true;
    const body = {
      phone_number: this.businessDetails.agent_phone_number,
      type: this.direction
    };
    this.appService.callLogs(body).subscribe(res => {
      this.records = res;
      this.isLoading = false;
      this.fetchCallTagsUsingRecords(res);
    }, error => {
      console.error('Error fetching call logs:', error);
      this.isLoading = false;
    }
    );
  }

  private fetchCallTagsUsingRecords(records: any) {
    this.isLoading = true;
    let callSidArr = [];
    this.recordsSidTagsMap = {};
    for (let record of records) {
      callSidArr.push(record.sid);
      if (!(record.sid in this.recordsSidTagsMap)) {
        this.recordsSidTagsMap[record.sid] = [];
      }
    }
    this.appService.fetchCallTags(callSidArr).subscribe({
      next: (resolve) => {
        const twilioCallHistory = resolve.data.twilio_call_history;
        for (let item of twilioCallHistory) {
          this.recordsSidTagsMap[item.call_sid] = item.intent_keywords_array;
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching call tags:', error);
        this.isLoading = false;
      }
    });
  }

  onFromDateChange(): void {
    if (this.from_date) {
      const fromDate = new Date(this.from_date);
      this.minToDate = this.utilsService.formatDate(fromDate);
      this.to_date = this.utilsService.calculateDate(fromDate, 6);
      this.maxToDate = this.utilsService.calculateDate(fromDate, 6);
    } else {
      this.resetDates();
    }
  }

  formatTags(sid: string): string {
    let result: string = "";
    const tags: string[] = this.recordsSidTagsMap[sid];
    if (tags.length === 0) return "-";
    for (let i = 0; i < tags.length; i++) {
      result += tags[i];
      if (i != tags.length - 1) {
        result += ", "
      }
    }
    return result;
  }

  private resetDates(): void {
    this.minToDate = '';
    this.maxToDate = '';
    this.to_date = '';
  }

  closePopup(): void {
    this.isPopupOpen = "";
  }

  callDetailsById(): void {
    this.direction = '';
    const body = {
      callSid: this.caller_id
    };
    this.appService.callDetailsById(body).subscribe(res => {
      this.openCallDetails(res)
    }, error => {
      console.error('Error fetching call details:', error);
    });
  }

  changeCallLogDirection(): void {
    this.isLoading = true;
    const body = {
      phone_number: this.businessDetails.agent_phone_number,
      type: this.direction
    };
    this.appService.callLogs(body).subscribe(res => {
      this.records = res;
      this.isLoading = false;
      this.fetchCallTagsUsingRecords(res);
    }, error => {
      console.error('Error fetching call logs:', error);
      this.isLoading = false;
    });
  }

  getNumber() {
    this.numberSelectorPopup = true;
  }

  openCallDetails(details: any): void {
    this.appService.getCallRecordingId(details).subscribe((res: any) => {
      this.isPopupOpen = details;
      if (res.data.twilio_call_history.length) {
        this.isPopupOpen.rec = res.data.twilio_call_history[0].record_sid;
        this.isPopupOpen.intent_keywords_array = this.utilsService.updateArrayValues(res.data.twilio_call_history[0].intent_keywords_array);
        this.recURL = `${this.apiUrl}/audio/${this.isPopupOpen.rec}`
      }
    });
  }

  formatTime(second: number): string {
    const minutes = Math.floor(second / 60);
    const seconds = second % 60;

    return `${minutes}m ${seconds}s`;
  }

  downloadFile() {
    const baseUrl = `${this.apiUrl}/audio/`;
    const filename = this.isPopupOpen.rec + '.mp3';
    const fileUrl = baseUrl + this.isPopupOpen.rec;

    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, filename);
      });
  }
}
